/** 
 * ACCOUNT
 */

import { formatDate } from "@/utils/format-date.utils"
import { Pack } from "./pack.types"
import { Project } from "./project.types"
import { Subscription } from "./subscription.types"
import { Supervisor } from "./supervisor.types"
import { Template } from "./template.types"
import { User } from "./user.types"
import { CONFIG_AXIS_COLORS } from "./axis.types"

//List of HRIS integrations supported by IDTree
export type AccountIntegration = "lucca" | "peoplesphere"

//First choice for onboarding
//Track what action the user have done in the first place
export type AccountOnboardingAction = "project" | "templates" | "neither"

//Type of display for the results
//> note : mean of the score
//> satisfaction : ratio of positive answers vs ratio of negative answers
export type AccountScoreResult = "note" | "satisfaction"

//For a superadmin account define permission for every account
//> all : the children account have access to all templates
//> account : the children account have only access to the template of the superamdin account
//> public : the children account have only access to the topics of IDTree
//> list : the children account have access to a specific list of templates (defined in templateIds)
//> blocked : the children account can't access to any template
export type AccountTemplateRule = "all" | "account" | "public" | "list" | "blocked"

//Zone for an account (for better quickvote application availability)
export type AccountZone = "europe" | "america" | "asia"

//Level for the app
export type AccountLevel = "free" | "starter" | "smb" | "enterprise"

//List of attributes that will be saved in sendInBlue when an account will be created in self-service
export class AccountAdsOptions{
  SOURCE : string | null = ""
  CAMPAIGNID : string | null = ""
  COMPANY_SIZE : string | null = ""
  ADGROUPID : string | null = ""
  KEYWORD : string | null = ""
  GCLID : string | null = ""
  MESSAGE : string | null = ""
  TARGETID : string | null = ""
  TRIAL : boolean = true
  TRIAL_SOURCE : string | null = ""
  TRIAL_PAGE : string | null = ""
  PERSONA : string | null = null
  constructor(accountAdsOptions: Partial<AccountAdsOptions> = {}){
    Object.assign(this, accountAdsOptions)
  }
}

export type Tag = {
  color: string
  name: string
}

//Type of results for the heatmap
export type AccountHeatmapResult = "axes" | "topics" | "questions" | "survey"
export const ACCOUNT_RESULTS_TYPES: AccountHeatmapResult[] = ["axes", "topics", "questions"]

//Color palette
export class AccountColors{
  active : string = "#FFA01A"
  brandPrimary : string =  "#4fd683"
  brandSecondary : string =  "#4fd683"
  brandText : string =  "#FFFFFF"
  cta : string = "#51E899"
  email : string = "#00CB7B"
  tags: Tag[] = CONFIG_AXIS_COLORS.map((color: string) => ({ color, name: "" }))
  constructor(accountColors: Partial<AccountColors> = {}){
    Object.assign(this, accountColors)
  }
}

//Premium modules that can be activated
export class AccountModules{
  qrcode : boolean = false //Can print invitations with QRCode
  sms : boolean = false //Can send invitations by SMS
  segment : boolean = false //Can send survey or axes to specific populations
  languages : boolean = false //Can use multiple languages
  learning : boolean = false //Can consult e-learning content
  nps : boolean = false //Net promoter score widget
  report: boolean = false //Report generator for survey results
  rps : boolean = false //Can display psychosocial hazards dashboards
  integration : boolean = false //Can use integration with HRIS
  sso : boolean = false //Connexion with SSO
  task: boolean = false //Action plans
  assistant: boolean = false //AI assistant
  populations: boolean = false //Results by population (flute de pan)
  constructor(accountModules: Partial<AccountModules> = {}){
    Object.assign(this, accountModules)
  }
}

//List of options that can be specific for an account
export class AccountOptions {
  allowAssistant: boolean = false; //Allow use of AI for advices
  allowObserverSettings: boolean = false; //Observers can edit their view
  ceilsBirthDate: number[] = [35, 50]; //Values in years for ages populations
  ceilsCompanyWelcomeDate: number[] = [24, 120]; //Values in months for seniority
  ceilsTesters: number = 5; //Maxium of testeurs for a test survey
  ceilsRepartition: number[] = [6, 8]; //Ceils of satisfaction for the repartition widget
  customEmailSenderInstance: boolean = false; //Uses custom instance for sending email (instances are configured in the mail.utils file)
  dashboardDisplayMode: AccountScoreResult = "note"; //show note /10 or % of satisfaction
  dashboardNightmareModeHeatmap: boolean = false; //Display custom display for the heatmap
  dashboardNightmareModeDisplay: boolean = false; //Display custom colors for the tree
  dashboardNightmareModeMax: number = 80; //Ceil max for the nighmare mode (note > 80 will be in specific color)
  dashboardNightmareModeMin: number = 50; //Ceil min for the nighmare mode (note < 50 will be in specific color)
  dashboardNightmareColor1: string = "#eb5a46"; //Display color for topics where note < dashboardNightmareModeMin
  dashboardNightmareColor2: string = "#CCCCCC"; //Display color for topics where dashboardNightmareModeMin < note < dashboardNightmareModeMax
  dashboardNightmareColor3: string = "#1CB06E"; //Display color for topics where note > dashboardNightmareModeMax
  defaultIcon: string = "institutions/shield"; //Default icon for multiple account
  disableChat: boolean = false; //Disable chat information
  filterOrder: string[] = []; //Order to display filters in dashboard (for supervisor)
  gollacFilterName: null | string = null; //For heatmap gollac => default filter name not specified
  gollacManagerAttributeName: null | string = null; //For heatmap gollac => default filter attribute not specified
  ceilsGollacMinValue: number = 10; //For heatmap gollac => default ceils
  ceilsGollacMaxValue: number = 20; //For heatmap gollac => default ceils
  heatmapFilterName: null | string = null; //For heatmap => default filter name specified
  heatmapDisplayCompany: boolean = true; //For heatmap => display company column by default
  heatmapDisplayParticipation: boolean = true; //For heatmap => display participation row by default
  heatmapDisplayNoScore: boolean = true; //For heatmap => hide columns without results
  heatmapResultsType: AccountHeatmapResult | null = null; //For heatmap => type of results expected by default (axes, questions...)
  isUnlimited: boolean = false; //The account can launch any numbers of survey he want in the year
  isObserverNotRestricted: boolean = false;
  integration: null | AccountIntegration = null; //Integration with HRIS options
  image: boolean = false; //Is a picture uploaded for the account
  lastUpload: Date | null = null; //Date of the last excel upload
  onboardingAction: AccountOnboardingAction | null = null; //Onboarding first action
  pastResults: boolean = true; //Allow user access to survey submited answers
  pastResultsEmail: boolean = false; //Send email containing results link to users finishing survey (pastResults must be true)
  ssoOrganization: string = ""; //Identifier of the sso organization (in cryptr)
  topicRepartitionCeil: number = 50; //Ceil for positive vote for topic repartition
  hideNoAnswers: boolean = false; //Don't show count of no answer by questions
  hideStartedForParticipation: boolean = false; //Don't show the "started in the dashboard"
  genderShowFilter: boolean = true; //display gender filter in dashboard
  birthDateShowFilter: boolean = true; //display birth date filter in dashboard
  companyWelcomeDateShowFilter: boolean = true; //display company welcome date filter in dashboard
  unlimitedUsers: number = 0; //Limit of users (for unlimited surveys)
  zone: AccountZone = "europe"; //Change zone (for different quickvote link each bond to specific server and region)
  constructor(item: Partial<AccountOptions> = {}) {
    Object.assign(this, item);
  }
}

/**
 * ACCOUNT
 */
export class Account{
  allowedTemplates : string[] = [] //List of templates allowed
  colors : any = new AccountColors()
  dateStart : Date = new Date()
  level : AccountLevel = "free" //Default level for the app
  id : string = ""
  isPasswordRequired:boolean = false //A password is required for observer and manager
  isSelfService : boolean = false //The customer is come by his own way
  isSuperAccount : boolean = false //The account can manage other accounts (consultive firm with many customers or big company)
  lastSubscriptionEnd: number | null = null
  modules : AccountModules = new AccountModules()
  name : string = ""
  options : AccountOptions = new AccountOptions()
  participationMin : number = 5
  superAccountId : string | null = null
  templateRule : AccountTemplateRule = "all" //Template rule
  Packs : Pack[] = []
  Projects : Project[] = []
  Subscriptions : Subscription[] = []
  SupervisorId : string | null = null
  SupervisorIdSales : string | null = null
  Supervisor : Supervisor | null = null
  SupervisorSales : Supervisor | null = null
  Templates : Template[] = []
  Users : User[] = []

  constructor(account: Partial<Account> = {}){

    if (!account) account = new Account()

    account.options = new AccountOptions(account.options)
    account.colors = new AccountColors(account.colors)
    account.Packs = account.Packs ? account.Packs.map(x => new Pack(x)) : []
    account.Subscriptions = account.Subscriptions ? account.Subscriptions.map(x => new Subscription(x)) : []
    if (account.Supervisor) account.Supervisor = new Supervisor(account.Supervisor)
    if (account.SupervisorSales) account.SupervisorSales = new Supervisor(account.SupervisorSales)
    account.Projects = account.Projects ? account.Projects.map(x => new Project(x)) : []
    account.Templates = account.Templates ? account.Templates.map(x => new Template(x)) : []
    account.modules = account.modules ? new AccountModules(account.modules) : new AccountModules()

    Object.assign(this, account)

  }

  //Get date formated
  get dateStartLabel():string{
    return formatDate(this.dateStart, true, false)
  }

  //Get status of survey for account
  //If launch or program display message
  get projectStatus():string{
    if (this.Projects.length){
      return this.Projects[0].statusLabel
    }else{
      return ""
    }
  }

}

//Smartlink Account
export const ACCOUNT_DEMO = new Account({ 
  id : '1dbad5e4-93d4-4d85-871a-21dc9a17a0ad',
  name : 'SmartLink'
})

//Smartlink Account
export const ACCOUNT_DEMO_GROUP = new Account({ 
  id : '9d10afe9-eebf-4fc7-8b45-17aadb5016d2',
  name : 'SmartGroup'
})

//Different kinds of level
export const ACCOUNT_LEVELS:AccountLevel[] = [
  "free",
  "starter",
  "smb",
  "enterprise"
]

export interface AccountState{
  active : Account //Compte sélectionné
  ascOrDesc : "asc" | "desc" //order to display accounts (asc or desc)
  count : number //Nombre de comptes total
  countSearch : number //Nombre de comptes recherchés
  list : Account[] //Liste de comptes
  order : string //order too display accounts (field name)
  status : string //Statut en cours
}

export const ACCOUNT_ACTIVATE : string = "ACCOUNT_ACTIVATE"
export const ACCOUNT_ADD : string = "ACCOUNT_ADD"
export const ACCOUNT_EDIT : string = "ACCOUNT_EDIT"
export const ACCOUNT_GET : string = "ACCOUNT_GET_ALL"
export const ACCOUNT_GET_COUNT : string = "ACCOUNT_GET_COUNT"
export const ACCOUNT_GET_COUNT_SEARCH : string = "ACCOUNT_GET_COUNT_SEARCH"
export const ACCOUNT_INIT : string = "ACCOUNT_INIT"
export const ACCOUNT_ORDER : string = "ACCOUNT_ORDER"
export const ACCOUNT_REMOVE : string = "ACCOUNT_REMOVE"
export const ACCOUNT_STATUS : string = "ACCOUNT_SET_STATUS"