/**
 * API-MIDDLEWARE.UTILS
 * Behavior to apply for every API call with idtree services
 * Format { error } message by triggering a response 
 */

import { store } from '@/index';
import { camelCase, forOwn, mapKeys, snakeCase } from 'lodash';
import { toast } from 'react-toastify';
import { DashboardFilters } from '@/redux/filter.types';
import { Survey } from '@/redux/survey.types';
import { INSUFFICIENT_PARTICIPATION } from '@/redux/_status.types';
import { instanceBackend, instanceNumind, instanceQrcode, instanceReporting } from "../App";
import { dateMonthsToRange } from './date-months-to-range.utils';
import { dateYearsToRange } from './date-years-to-range.utils';
import { AccountOptions } from '@/redux/account.types';
import { sessionShowError } from '@/redux/_session.actions';
import filtersToAttributes from './filters-to-attributes.utils';
import { t } from '@/translate/t';


function processError(errorResponse: any){

  let code: string

  if (errorResponse.status === 404) code = "utils_server_empty"
  else if (errorResponse.status >= 500) code = "utils_network_error"
  else if (errorResponse.status === 403) code = "utils_server_unauthorized"
  else code = errorResponse.data.error

  return code
    
}

const apiMiddleware = () => next => action => {
  return new Promise(function(resolve) {
    next(action)
    
    //Middleware is active only for action of type API or ARCHIVE AND QRCODE henceforth
    if (
      action.type !== "API" &&
      action.type !== "ARCHIVE" &&
      action.type !== "NUMIND" &&
      action.type !== "QRCODE" &&
      action.type !== "UPLOAD"
    ) return;

    //Items of the payload of the action to include in the middlewaire
    //- method : type of HTTP method (POST, GET, PUT, DELETE)
    //- data : form data (add theses action in body or params)
    const { method, data } = action.payload;
    let { url } = action.payload;

    //Detect how to form param (if GET or DELETE )
    const dataOrParams = ["GET", "DELETE"].indexOf(method) > -1 ? "params" : "data";

    switch(action.type){

      case 'API':

        if (data?.dashboardFilters){
          data.dashboardFilters = JSON.stringify(data.dashboardFilters)
        }

        //Envoyer données au backend
        instanceBackend.request({
          url,
          method,
          [dataOrParams]: data
        }).then(({ data }) => {

          if (data.error){

            //IF errors array (sequelize)
            if (data.error.errors){
              toast(t(snakeCase(data.error.errors[0].message)), { type : 'error' });
            }else{
              toast(t(data.error), { type : 'error' });
            }
            
          }

          resolve(data); 

        }).catch(error => { 
          if (error.response){

            const code: string = processError(error.response)
            
            toast(t(code) + (error.response.data.details ? " " + error.response.data.details : ""), { type : 'error' });
            resolve({ error : code });

          }
          else{
            store.dispatch(sessionShowError(t("middleware_error_timeout"), error.toString()))
            resolve({ error : 'network error', details : error });
          }
        });

        break

      case "ARCHIVE":

        //Redirect if no token
        if (instanceReporting.defaults.headers.common['reportingToken'] === "null" && url.indexOf("/auth") === -1){
          //window.location.href = window.location.origin + "/login/app?message=session_expired"
        }else{

          //Get current redux store
          const queryItems:string[] = []
          const activeSurvey: Survey = store.getState().survey.active
          const dashboardFilters = new DashboardFilters(action.payload.dashboardFilters)
          const surveyId: string = action.payload.surveyId ? action.payload.surveyId : activeSurvey.id
          const surveyStart: Date = action.payload.surveyStart ? new Date(action.payload.surveyStart) : new Date(activeSurvey.dateStart)
          const accountOptions: AccountOptions = store.getState()._session.accountOptions

          //Get attributes
          let attributes = ""
          if (!action.payload.overrideDashboardFilters){
            attributes = filtersToAttributes(dashboardFilters.customFilters, store.getState().filter.observerAttributes, store.getState()._session.accountOptions.isObserverNotRestricted, store.getState()._session.userRole === "OBSERVER")
          }
          
          //Join all attributes selected
          if(attributes.length > 0){
            queryItems.push(attributes)
          }

          if (action.payload.dashboardFilters){

            //Get date of reference

            if (dashboardFilters.gender){
              queryItems.push("user_gender=" + dashboardFilters.gender)
            }

            //Create range of dates for birthDate
            if (dashboardFilters.birthDate){
              queryItems.push("user_birth_date_range=" + dateYearsToRange(surveyStart, accountOptions.ceilsBirthDate, dashboardFilters.birthDate))
            }

            //Create range of dates for companyWelcomeDate
            if (dashboardFilters.companyWelcomeDate){
              queryItems.push("user_company_welcome_date_range=" + dateMonthsToRange(surveyStart, accountOptions.ceilsCompanyWelcomeDate, dashboardFilters.companyWelcomeDate))
            }

          }

          //Add query
          url = url + "?survey_id=" + surveyId

          if (queryItems.length){
            url = url + "&" + queryItems.join("&")
          }

          if (data){
            forOwn(data, (value, key) => {
              if (value !== null){
                url = url + "&" + snakeCase(key) + "=" + value
              }
            })
          }

          //Replace potential errors in url (for attributes)
          url = url.replace("attributes=;", "attributes=")
          url = url.replace(";;", ";")

          instanceReporting.request({
            url,
            method : "GET"
          }).then(({ data }) => {

            if (!data){
              data = {}
            }

            if (data.error){

              //Do not display toast if participation error
              if (data.error !== INSUFFICIENT_PARTICIPATION){
                toast(data.error, { type : 'error' });
              }

              resolve({ error : data.error })

            }
            else if (Array.isArray(data)){

              const items:any[] = []
              data.forEach(obj => {
                items.push(mapKeys(obj, (v, k) => {
                  return camelCase(k)
                }))
              })

              resolve(items)

            }else if (url.indexOf("/auth") === -1){
              resolve(mapKeys(data, (v, k) => {
                return camelCase(k)
              }))
            }else{
              resolve(data)
            }

          }).catch(error => {

            if (error.response){

              const code: string = processError(error.response)

              toast(t(code) + error.response.data.details, { type : 'error' });
              resolve({ error : code });

            }
            else{
              store.dispatch(sessionShowError(t("middleware_error_timeout"), error.toString()))
              resolve({ error : 'network', details : error });
            }

          })

        }

        break

      case "NUMIND":
        instanceNumind.request({
          url,
          method,
          [dataOrParams]: data
        }).then(({ data }) => {

          if (data.error){
            toast(t(data.error) , { type : 'error' });
          }

          resolve(data); 

        })
        .catch(error => { 
          if (error.response){

            const code: string = processError(error.response)

            toast(t(code) + error.response.data.details, { type : 'error' });
            resolve({ error : code });

          }
          else{
            store.dispatch(sessionShowError(t("middleware_error_timeout"), error.toString()))
            resolve({ error : 'network', details : error });
          }
        })
        break

      case "QRCODE":

        instanceQrcode.request({
          url,
          method,
          [dataOrParams]: data
        }).then(({ data }) => {

          if (data.error){
            toast(t(data.error) , { type : 'error' });
          }

          resolve(data); 

        })
        .catch(error => { 
          if (error.response){

            const code: string = processError(error.response)

            toast(t(code) + error.response.data.details, { type : 'error' });
            resolve({ error : code });

          }
          else{
            store.dispatch(sessionShowError(t("middleware_error_timeout"), error.toString()))
            resolve({ error : 'network', details : error });
          }
        })
        
        break

      //Config spécifique si upload (possible transfert de fichiers)
      case "UPLOAD": {

        //Construction du formulaire
        const formData = new FormData();
        formData.set('folder', data.folder);
        formData.set('id', data.id);
        formData.set('extension', data.extension);
        formData.set('item', data.item);
        formData.set('type', data.type);
        formData.set('skin', data.skin);
        formData.append('file', data.file);
        formData.append('image', data.image);
        formData.append('skin', data.skin);
        
        //Upload
        instanceBackend.request({
          url,
          method,
          [dataOrParams]: formData
        }).then(({ data }) => { 
          resolve(data); 
        })
        .catch(error => {
          resolve({ error : 'network error', details : error }); 
        })

        break
      }
      default:
        //error
        toast(t("error_uncorrect_action_type"), { type : 'error' });
        break
      
    }

  });

};

export default apiMiddleware;