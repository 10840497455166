/** 
 * PAGE
 * Survey report page config
 */

import { RawDraftContentBlock } from "draft-js"
import { Topic } from "./topic.types"
import { AccountHeatmapResult } from "./account.types"
import { Question } from "./question.types"
import { Population } from "./population.types"
import { sum } from "lodash"

//Type of content available
export const PAGE_CONTENT_TYPES = ["text", "image", "screenshot"] as const
export type PageContentType = typeof PAGE_CONTENT_TYPES[number]

//Type of screenshots
export const PAGE_CONTENT_SCREENSHOT_TYPES = ["global_results", "heatmap", "axes", "podium", "questions", "messages"] as const
export type PageContentScreenshotType = typeof PAGE_CONTENT_SCREENSHOT_TYPES[number]

//List of param that can be provided for a type
export const PAGE_CONTENT_SCREENSHOT_PARAMS = ["filterName", "resultsType", "displayAll", "summarize", "displayFullScale", "customTitle", "displayQuestions"] as const
export type PageContentScreenshotParam = typeof PAGE_CONTENT_SCREENSHOT_PARAMS[number]

//Config for each screenshot type
//Define which params should be declared for each type
export interface PageContentScreenshotConfig{
  type : PageContentScreenshotType
  params : PageContentScreenshotParam[]
}
export const PAGE_CONTENT_SCREENSHOT_CONFIGS:PageContentScreenshotConfig[] = [
  { type : "global_results", params : ["customTitle"] },
  { type : "axes", params : [] },
  { type : "heatmap", params : ["resultsType", "filterName"] },
  { type : "questions", params : ["displayAll", "displayFullScale"] },
  { type : "podium", params : ["displayQuestions"] },
  { type : "messages", params : ["displayAll", "summarize"] }
]

//List of mode for filtering the data
export const PAGE_FILTER_MODES = ["none", "one", "all"] as const
export type PageFilterMode = typeof PAGE_FILTER_MODES[number]

//List of tags for display survey data
export const PAGE_CONTENT_TAGS = [
  "account_name",
  "account_note",
  "account_note_last_1",
  "account_note_last_2",
  "account_note",
  "account_participation_rate",
  "account_participation_rate_last_1",
  "account_participation_rate_last_2",
  "axes_names",
  "participation_min",
  "population_name",
  "survey_date_start",
  "survey_date_end",
  "survey_name",
  "survey_note",
  "survey_note_last_1",
  "survey_note_last_2",
  "survey_participation_rate",
  "survey_participation_rate_last_1",
  "survey_participation_rate_last_2",
  "survey_year",
  "survey_year_last_1",
  "survey_year_last_2"
] as const
export type PageContentTag = typeof PAGE_CONTENT_TAGS[number]

//Sides for content (can be left or right)
export const PAGE_SIDES = ["contentLeft", "contentRight"] as const
export type PageSide = typeof PAGE_SIDES[number]

//Param for the heatmap (limit of rows displayed)
export const PAGE_HEATMAP_LIMIT = 6

//Data option for fetching survey results for the reporting
export class PageContentScreenshot{
  type : PageContentScreenshotType | null = null
  customTitle : boolean = false
  displayAll : boolean = true
  displayFullScale : boolean = true
  displayQuestions : boolean = false
  hideItems : string[] = []
  resultsType : AccountHeatmapResult = "questions"
  filterName : string = ""
  summarize : boolean = false
  constructor(item: Partial<PageContentScreenshot> = {}){
    if (!item) item = new PageContentScreenshot()
    Object.assign(this, item)
  }

  get config():PageContentScreenshotConfig | undefined{
    return PAGE_CONTENT_SCREENSHOT_CONFIGS.find(x => x.type === this.type)
  }

  get params():PageContentScreenshotParam[]{
    const config = this.config
    return config ? config.params : []
  }

  //Dispplay topic for the page
  //Detect if topic is hidden or not
  //Then detect if questions are answered
  isTopicDisplayed(topic:Topic):boolean{
    const showTopic = topic.Questions.filter(x => (x.type === "scale" && x.repartition.length > 0) || (x.type === "choice" && sum(x.Propositions.map(x => x.count)) > 0)).length > 0
    return showTopic && !this.isTopicHidden(topic)
  }

  //Detect if the topic is hidden or not
  isTopicHidden(topic:Topic):boolean{
    return this.displayAll ? false : this.hideItems.indexOf(topic.aid ? topic.aid: "") > -1
  }

}

//Content to display for the page
export class PageContent{
  textBlocks : RawDraftContentBlock[] = []
  type : PageContentType = "text"
  imageId : string | null = null
  screenshot : PageContentScreenshot = new PageContentScreenshot()
  constructor(item: Partial<PageContent> = {}){
    if (!item) item = new PageContent()
    item.screenshot = new PageContentScreenshot(item.screenshot)
    Object.assign(this, item)
  }
}

export class PageOptions{
  filterMode : PageFilterMode = "none"
  filterName : string | null = null
  populations : Population[] = []
  constructor(item: Partial<PageOptions> = {}){
    if (!item) item = new PageOptions()
    item.populations = item.populations ? item.populations.map(x => new Population(x)) : []
    Object.assign(this, item)
  }
}

//Page of the report
export class Page {
  backgroundId : number | null = null //Id of the selected background
  contentLeft : PageContent = new PageContent() //Page content on the left side (or full page if no rightContent)
  contentRight : PageContent = new PageContent() //Content of the right side
  heatmapOffset : number = 0 //Offset for the heatmap (in order to print heatmap on multiple pages if too many rows)
  generativeAIText : string = "" //Text generating for AI Summarize
  twoColumns : boolean = false
  id : string = "" //Id of the page
  name : string = "" //Name of the page displayed as title of the page
  timestamp : number = 0 //Date creation
  status : "new" | "edited" | "saved" = "new" //Status for the page (in order to save)
  ReportId : string = "" //Id of the report
  options : PageOptions = new PageOptions() //options for the page
  Topics : Topic[] = [] //For loop on each topic
  QuestionOpen : Question = new Question()

  constructor(item: Partial<Page> = {}) {
    if (!item) item = new Page()
    item.contentLeft = new PageContent(item.contentLeft)
    item.contentRight = new PageContent(item.contentRight)
    item.options = new PageOptions(item.options)
    Object.assign(this, item)
  }

  //Get date from timestamp
  get date(): string {
    return new Date(this.timestamp).toLocaleDateString()
  }

  //Get index of the page in the list
  getIndex(list:Page[]):number {
    return list.findIndex(x => x.id === this.id)
  }

}

export interface PageState {
  active: Page
  list: Page[]
  status: string
}

export const PAGE_ACTIVATE: string = "PAGE_ACTIVATE"
export const PAGE_ADD: string = "PAGE_ADD"
export const PAGE_CHANGE: string = "PAGE_CHANGE"
export const PAGE_EDIT: string = "PAGE_EDIT"
export const PAGE_GET: string = "PAGE_GET"
export const PAGE_INIT: string = "PAGE_INIT"
export const PAGE_REMOVE: string = "PAGE_REMOVE"
export const PAGE_REPLACE: string = "PAGE_REPLACE"
export const PAGE_STATUS: string = "PAGE_STATUS"