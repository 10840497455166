/** 
 * TEMPLATE-OPTIONS.WIDGET
 * Customize template options
 */

import { connect } from "react-redux"
import { store } from "@/index";
import { withTranslation, WithTranslation } from "react-i18next";
import { Session } from "@/redux/_session.types";
import Card from "@/components/card";
import Checkbox from "@/components/checkbox";
import TextInput from "@/components/text-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Template, TemplateOptions, TemplateState } from "@/redux/template.types";
import { templateEditAndUpdate } from "@/redux/template.actions";
import { useState } from "react";
import Space from "@/components/space";
import Button from "@/components/button";
import getEmoji from "@/utils/get-emoji.utils";
import Range from "@/components/range";
import { Question, QuestionsTopic } from "@/redux/question.types";
import i18n from "@/translate/i18n";

interface StateProps extends WithTranslation{
  template : TemplateState
  _session : Session
}

function TemplateOptionsWidget(props:StateProps) {
  const { t } = props

  const [isEdited, setIsEdited] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [templateOptions, setTemplateOptions] = useState<TemplateOptions>(props.template.active.options)

  //On click cancel
  //Reset all parameters from the store
  function cancel(){
    setIsEdited(false)
    setTemplateOptions(props.template.active.options)
  }

  function edit(key, value){
    setTemplateOptions(Object.assign({}, templateOptions, { [key] : value }))
    setIsEdited(true)
  }

  function save(){
    setIsLoading(true)
    store.dispatch(templateEditAndUpdate("options", templateOptions))
    setTimeout(() => {
      setIsEdited(false)
      setIsLoading(false)
    }, 500)
  }

  function getTemplateResponseWidget(i:number){
    const key = "response" + i + "Label"
    const templateResponse = templateOptions[key]

    return (
      <div key={i}>

        <Checkbox active={templateResponse}
          onClick={(e) => edit(key, e.value)}
          text={i === 5 ? t("question_type_scale_5_alt") : t("question_type_scale_" + i)}
        />
  
        { templateResponse &&
        <div>

          { i === 0 &&
          getTemplateResponseLabelWidget(i, "nspp", "question")
          }

          { i === 2 &&
          <div>
            {getTemplateResponseLabelWidget(i, "yes", "utils")}
            {getTemplateResponseLabelWidget(i, "no", "utils")}
          </div>
          }

          { (i === 4 || i === 5) &&
          <div>

            { [...Array(5)].map((e, j) =>
            getTemplateResponseLabelWidget(i, (5 - j).toString(), "question_likert")
            )
            }

          </div>
          }

          { i === 10 &&
          <div>
            {getTemplateResponseLabelWidget(i, "happy", t("question_nps"))}
            {getTemplateResponseLabelWidget(i, "unhappy", t("question_nps"))}
          </div>
          }

          { i === 100 &&
          <div>
            {getTemplateResponseLabelWidget(i, "message", t("question_open_help"))}
          </div>
          }

          { i === 1000 &&
          <div>
            {getTemplateResponseLabelWidget(i, "yes", t("question_emoji"))}
            {getTemplateResponseLabelWidget(i, "no", t("question_emoji"))}
          </div>
          }

          { i === 10000 &&
          <div>
            {getTemplateResponseLabelWidget(i, "", t("question_withmessage_preview"))}
          </div>
          }

        </div>
        }
        
      </div>
    )
  }

  function getTemplateResponseLabelWidget(responseCount:number | string, key:string, labelPrefix:string){
    return (
      props.template.active.languages.map((lg, i) =>
        <div className="flex medgrey-b" key={responseCount + key}
          style={{ 
            borderTop : i === 0 && props.template.active.languages.length > 1 ? "1px solid" : ""
          }}>
          
          { i === 0
          ?
          <div className="flex1 grey-t flex flex-auto">

            <FontAwesomeIcon icon={faChevronRight}
              style={{ fontSize : 18 }}
            />

            &nbsp;

            {t(labelPrefix + (key.length ? "_" + key : ""))}

          </div>
          :
          <Space/>
          }

          {getTemplateResponseInputWidget(responseCount, key, lg)}
          
        </div>
      )
    )
  }

  function getTemplateResponseInputWidget(responseCount:number | string, key:string, lg:string){
    const keyLabel: string = "response" + responseCount + "Label_" + key + "_" + lg
    const label = templateOptions[keyLabel]
    return (
      <div className="flex">

        { props.template.active.languages.length > 1 &&
        <div className="grey-t flex flex-auto">
          <b>
            {lg.toUpperCase()}
          </b>
          &nbsp;
          {getEmoji(lg)}
        </div>
        }

        <div className="width-10"/>

        <TextInput key={lg}
          value={label}
          onChange={(e) => edit(keyLabel, e.value)}
        />

      </div>
    )

  }

  //Toogle emoji
  //Activate custom label for the response scales
  function toogleEmojiMode(emojiMode:boolean){

    const templateOptions = Object.assign({}, props.template.active.options, {
      emojiMode,
      responseEmojiLabel_yes_fr : emojiMode ? "😃" : "",
      responseEmojiLabel_no_fr : emojiMode ? "🙁" : ""
    })

    setTemplateOptions(templateOptions)
    setIsEdited(true)

  }

  return (
    <Card title={t("account_settings_preferences_template")}>

      <Checkbox onClick={(e: any) => edit("showCircle", e.value)}
        active={templateOptions.showCircle}
        text={t("template_options_show_circle")}
      />

      <Checkbox onClick={(e: any) => toogleEmojiMode(e.value)}
        active={templateOptions.emojiMode}
        text={t("question_emoji")}
      />

      { templateOptions.emojiMode &&
      <div>
        {getTemplateResponseLabelWidget("Emoji", "yes", "question_emoji_reverse")}
        {getTemplateResponseLabelWidget("Emoji", "no", "question_emoji_reverse")}
      </div>
      }

      <div className="height-20"/>

      <p>
        <b>
          {t("template_scale_label")}
        </b>
      </p>

      {
      getTemplateResponseWidget(0)
      }

      { [2, 5, 10].map(i => 
      getTemplateResponseWidget(i)
      )
      }

      { /* free comment */
      getTemplateResponseWidget(100)
      }

      { /* free comment (QCM) */
      getTemplateResponseWidget(10000)
      }

      <div className="height-20"/>

      <p>
        <b>
          {t("template_scale_trigger")}
        </b>
      </p>

      <div className="height-10"/>

      <p>
        {t("template_scale_trigger_negative")}
      </p>

      <div className="flex">
        {new Question().getTriggerLabel(new Template({ options : templateOptions }), null, i18n.language, )}
      </div>

      <div className="flex">

        <Range onChange={(value) => edit("triggerNegativeAnswer", parseInt(value))}
          hideValue
          min={25}
          max={100}
          step={25}
          value={templateOptions.triggerNegativeAnswer}
        />

        <div className="width-20"/>

        <div className="grey-t">
          <div>
            - {new Question().getTriggerLabel(new Template({ options : templateOptions }), new Question({ id : "1" }), "")}
          </div>
          <div>
            - {new Question().getTriggerLabel(new Template({ options : templateOptions }), new Question({ id : "1", reverse : true }), "")} ({t("question_reverse_tag")})
          </div>
        </div>

      </div>

      <div className="height-10"/>

      <p>
        {t("template_scale_trigger_positive")}
      </p>

      <div className="flex">

        <Range onChange={(value) => edit("triggerPositiveAnswer", parseInt(value))}
          hideValue
          min={25}
          max={100}
          step={25}
          value={templateOptions.triggerPositiveAnswer}
        />

        <div className="width-20"/>

        <div className="grey-t">
          <div>
            - {new Question({ QuestionsTopic : new QuestionsTopic({ triggerValue : ">" }) }).getTriggerLabel(new Template({ options : templateOptions }), new Question({ id : "1" }), "")}
          </div>
          <div>
            - {new Question({ QuestionsTopic : new QuestionsTopic({ triggerValue : ">" }) }).getTriggerLabel(new Template({ options : templateOptions }), new Question({ id : "1", reverse : true }), "")} ({t("question_reverse_tag")})
          </div>
        </div>

      </div>

      <div className="height-20"/>

      { isEdited &&
      <div className="flex">
        <Space/>
        <Button onClick={cancel}>
          {t("utils_cancel")}
        </Button>
        <Button className="primary"
          isLoading={isLoading}
          onClick={save}>
          {t("utils_save")}
        </Button>
      </div>
      }

    </Card>
  )

}

const mapStateToProps = state => ({
  template : state.template,
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(TemplateOptionsWidget))