//List of routes for API call
import env from "@/env";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Supervisor } from "@/core/slices/supervisor/supervisor.types";
import { ApiError } from "@/core/utils.types";
import cookie from 'react-cookies';

// Define a service using a base URL and expected endpoints
export const backendApi = createApi({
  reducerPath: "backendApi",
  tagTypes: ["backend"],

  /** INFORMATIONS FOR ALL QUERIES (URL, JWT) */
  baseQuery: fetchBaseQuery({ 
    baseUrl: env.REACT_APP_URL_BACKEND,
    prepareHeaders: (headers)=> {
      const token = cookie.load('idtreeToken');
      if (token) headers.set('idtreeToken', token);
      return headers;
    }
  }),
  
  /** LIST OF ENDPOINTS */
  endpoints: (builder) => ({

    //Fetch all supervisors
    supervisorFetchAll : builder.query<Supervisor[] | ApiError, { search: string }>({
      query : ({ search }) => `supervisor/supervisors?search=${search}`
      /*async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        console.log(arg, queryFulfilled, dispatch)
      }*/
    })

  })

});

/*
type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/supervisors",
    data : {
      search
    }
  }*/

// Export hooks for usage in function components
export const {
  useSupervisorFetchAllQuery
} = backendApi;
