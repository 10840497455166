/**
 * SUPERVISOR.REDUCER
 */

import { Supervisor, SupervisorState, SUPERVISOR_ACTIVATE, SUPERVISOR_ADD, SUPERVISOR_EDIT, SUPERVISOR_GET, SUPERVISOR_INIT, SUPERVISOR_REMOVE, SUPERVISOR_STATUS } from "./supervisor.types";
import { v4 as uuid } from "uuid";
import { STATUS_LOADED } from "./_status.types";

let active:Supervisor

const initialState: SupervisorState = {
  active : new Supervisor(),
  list : [],
  status : STATUS_LOADED
}

export default function supervisorReducer(
  state = initialState,
  action: any
): SupervisorState {
  switch (action.type) {

    case SUPERVISOR_ACTIVATE:
      return Object.assign({}, state, {
        active : new Supervisor(action.payload.supervisor)
      })
    
    //Add new supervisor
    //Default level = 1
    case SUPERVISOR_ADD:
      active = new Supervisor()
      active.id = uuid()
      active.level = 1
      return Object.assign({}, state, {
        active,
        list : [active].concat(state.list)
      })

    case SUPERVISOR_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? active : x)
      })

    case SUPERVISOR_GET:
      return {
        ...state,
        list : action.payload.supervisors.map((x:any) => new Supervisor(x))
      }
    
    case SUPERVISOR_INIT:
      return Object.assign({}, state, {
        active : new Supervisor(initialState.active),
        list : state.list.map((x:any) => new Supervisor(x)),
        status : initialState.status
      })

    case SUPERVISOR_STATUS:
      return {
        ...state,
        status : action.payload.status
      }
    
    case SUPERVISOR_REMOVE:
      return {
        ...state,
        list : state.list.filter(x => x.id !== action.payload.id)
      }

    default:
      return state

  }
}