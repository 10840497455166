/**
 * DASHBOARD-HEATMAP.WIDGET
 * Display results in heatmap
 */
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  DashboardFilters,
  DEFAULT_FILTERS,
  DEFAULT_RANDOM_ATTRIBUTES,
  Filter,
  FilterLite,
  FilterState,
} from "@/redux/filter.types";
import {
  INSUFFICIENT_PARTICIPATION,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOAD_ERROR,
} from "@/redux/_status.types";
import NoResultsWidget from "./no-results.widget";
import { store } from "@/index";
// import store from "@/core/store";
import { filterReplaceDashboard, filterStatus } from "@/redux/filter.actions";
import { fetchAttributes, fetchHeatmap } from "@/redux/_archive.actions";
import ListItem from "@/components/list-item";
import Chip from "@/components/chip";
import { flatten, isEqual, max, mean, min, orderBy, uniq } from "lodash";
import PageLoader from "@/components/page-loader";
import Space from "@/components/space";
import { TopicState } from "@/redux/topic.types";
import { SurveyState } from "@/redux/survey.types";
import { generateHeatmap } from "@/redux/_generate.actions";
import { Axis, AxisState } from "@/redux/axis.types";
import PagePlaceholder from "@/components/page-placeholder";
import ContextMenu from "@/components/context-menu";
import initFilters from "@/utils/init-filters.utils";
import { BookmarkState } from "@/redux/bookmark.types";
import { NavigateFunction, useNavigate } from "react-router-dom";
import LoadingModal from "@/modals/loading.modal";
import { USER_LIST_GENDER } from "@/redux/user.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCog,
  faFilter,
  faSortAmountDownAlt,
  faSortAmountUp,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { Session } from "@/redux/_session.types";
import Card from "@/components/card";
import ListDropdown from "@/components/list-dropdown";
import ListButton from "@/components/list-button";
import HeatmapHelp from "@/help/heatmap.help";
import Modal from "@/components/modal";
import DashboardFiltersWidget from "./dashboard-filters.widget";
import getUserFilterDates, {
  UserFilterDates,
} from "@/utils/get-user-filter-dates.utils";
import getHeatmapCeils from "@/utils/get-heatmap-ceils.utils";
import { AttributeState } from "@/redux/attribute.types";
import { ProjectState } from "@/redux/project.types";
import Button from "@/components/button";
import {
  ACCOUNT_RESULTS_TYPES,
  AccountHeatmapResult,
} from "@/redux/account.types";
import { reportEdit } from "@/redux/report.actions";
import { axisFindTopicAid } from "@/redux/axis.actions";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import WbCloudyIcon from "@mui/icons-material/WbCloudy";
import { Box, TextField } from "@mui/material";

interface StateProps {
  _session: Session;
  attribute: AttributeState;
  axis: AxisState;
  bookmark: BookmarkState;
  filter: FilterState;
  survey: SurveyState;
  project: ProjectState;
  topic: TopicState;
}

interface OwnProps {
  id?: string;
  filterName?: string | null;
  resultsType?: string | null;
  isPreview?: boolean;
  limit?: number;
  offset?: number;
  isGollac?: boolean;
}

type Props = StateProps & OwnProps & WithTranslation;

//Index of first value item in response array (column)
const FIRST_COLUMN_INDEX = 1;

//Index of first value item in response array (line)
const FIRST_LINE_INDEX = 2;

const MODAL_FILTERS = "MODAL_FILTERS";
const MODAL_LOADING = "MODAL_LOADING";
const MODAL_HELP = "MODAL_HELP";

function DashboardHeatmapWidget(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  //Columns to display
  const [columns, setColumns] = useState<string[]>([]);

  //Length of the column text
  const [columnsTextLength, setColumnsTextLength] = useState<number>(0); //If text too long hide images

  //Data to display in lines
  const [data, setData] = useState<any[]>([]);

  // search / filter / sort heatmap
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">(
    !props.isGollac ? "desc" : "asc"
  );
  const [activeColumnIndex, setActiveColumnIndex] = useState<
    number | string | null
  >(!props.isGollac ? "firstCol" : 1);

  //Participation
  const [participations, setParticipations] = useState<any[]>([]);

  //Wait for location change to load
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //Colors for heatmap
  const [colorRed] = useState(
    props._session.dashboardDisplaySettings.dashboardNightmareModeHeatmap
      ? props._session.dashboardDisplaySettings.dashboardNightmareColor1
      : "#f1a8a3"
  );
  const [colorGreen] = useState(
    props._session.dashboardDisplaySettings.dashboardNightmareModeHeatmap
      ? props._session.dashboardDisplaySettings.dashboardNightmareColor3
      : "#99efc2"
  );

  //Is cell open and position in the grid of the current cell
  const [currentCellOpen, setCurrentCellOpen] = useState([false, 0, 0]);

  //Filter active
  const [currentFilter, setCurrentFilter] = useState<Filter>(
    initCurrentFilter()
  );

  //Current modal
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Type of data the query
  const [currentResultsType, setCurrentResultsType] =
    useState<AccountHeatmapResult>(initCurrentResultsType());

  //List of filters (contact default and custom)
  const [filters] = useState(initFilters());

  //Width of the first column (depends of the number of columns)
  const [firstColumnWidth, setFirstColumnWidth] = useState<number>(0);

  //Display company values
  const [showCompany, setShowCompany] = useState<boolean>(false);

  //Show population
  const [showPopulation, setShowPopulation] = useState<boolean>(false);

  //Load survey data on load or when filters or params are changes
  useEffect(() => {
    async function load() {
      store.dispatch(filterStatus(STATUS_LOADING));

      //Filtername to use for the query
      const filterName = currentFilter.default
        ? currentFilter.id
        : currentFilter.name;

      //Init show compnay
      const showCompany = props.isGollac
        ? false
        : props.filter.observerAttributes.length === 0 &&
          filterName !== "company" &&
          props._session.accountOptions.heatmapDisplayCompany;

      const showPopulation = props.isGollac
        ? false
        : (!isEqual(
            new DashboardFilters(props.filter.dashboard),
            new DashboardFilters()
          ) &&
            props._session.userRole === "ADMIN" &&
            filterName !== "company") ||
          (props.filter.observerAttributes.length > 0 &&
            filterName !== "company");

      setIsLoading(true);
      setShowCompany(showCompany);
      setShowPopulation(showPopulation);

      //Fetch data with currentFilter et currentResultsType as params
      //Different route if data is generate for demonstration dashboard
      const response: any = props.survey.active.randomData
        ? await store.dispatch(
            generateHeatmap(
              props.survey.active.template.id,
              props.survey.active.isTest ? props.survey.active.id : null,
              filterName,
              props.filter.dashboard
            )
          )
        : await store.dispatch(
            fetchHeatmap(
              filterName,
              currentResultsType,
              props._session.userInitialLanguage,
              props._session.accountOptions.heatmapDisplayNoScore,
              props._session.dashboardDisplaySettings.dashboardDisplayMode ===
                "satisfaction",
              props.filter.dashboard,
              getHeatmapCeils(filterName),
              props._session.dashboardDisplaySettings
                .hideStartedForParticipation,
              showCompany,
              showPopulation,
              props.isGollac ? props.isGollac : false,
              props._session.accountOptions.gollacManagerAttributeName,
              props._session.accountOptions.ceilsGollacMinValue,
              props._session.accountOptions.ceilsGollacMaxValue
            )
          );

      //Detect if participation count is ok
      if (response.error === INSUFFICIENT_PARTICIPATION) {
        store.dispatch(filterStatus(INSUFFICIENT_PARTICIPATION));
      } else {
        store.dispatch(
          filterStatus(!response.error ? STATUS_LOADED : STATUS_LOAD_ERROR)
        );
      }

      if (!response.error) {
        //Detect if the first column should be hidden
        let hideFirstColumn = false;
        if (props.filter.dashboard.customFilters.length === 1) {
          hideFirstColumn =
            props.filter.dashboard.customFilters[0].id === filterName;
        }

        //Make headers for the heatmap
        //For default filters : search translation
        //For customer filter : original name for reporting data / translation for demo data
        const newColumns: string[] = [];
        response.columns.slice(FIRST_COLUMN_INDEX).forEach((column) => {
          newColumns.push(
            currentFilter.default
              ? currentFilter.id === "gender"
                ? t("user_" + currentFilter.id + "_" + column[1])
                : currentFilter.id === "company"
                ? props.filter.observerAttributes.length > 0
                  ? props.filter.observerAttributes
                      .map((x) => x.name)
                      .join(", ")
                  : props._session.accountName
                : getUserFilterDates(
                    currentFilter.id === "birth_date"
                      ? "BIRTH_DATE"
                      : "COMPANY_WELCOME_DATE",
                    props.survey.active.dateStart
                  ).find((x: UserFilterDates) => x.id === column[1])?.name
              : props.survey.active.randomData
              ? t("filter_random_" + column[1])
              : column[1]
          );
        });

        if (response.population && !hideFirstColumn) {
          const attributesAids = flatten(
            props.filter.dashboard.customFilters.map((x) => x.attributesIds)
          );
          const attributesNames = props.attribute.list.filter(
            (x) => attributesAids.indexOf(x.id) > -1
          );
          if (attributesNames.length > 0) {
            newColumns.push(attributesNames.map((x) => x.name).join(", "));
          } else if (props.filter.observerAttributes.length > 0) {
            newColumns.push(
              props.filter.observerAttributes.map((x) => x.name).join(", ")
            );
          }

          if (currentResultsType === "axes"){
            response.population.forEach(item => {
              item.aid = axisFindTopicAid(item.aid)
            })
          }

        }

        //Add column for the company
        //If axis update the aid
        if (response.company){
          newColumns.push(props._session.accountName)
          if (currentResultsType === "axes"){
            response.company.forEach(item => {
              item.aid = axisFindTopicAid(item.aid)
            })
          }
        }

        //Get all questions (in order to match questions if results type is question)
        const questions = flatten(props.topic.list.map((x) => x.Questions));

        //If randomize data and type === axes or survey
        if (props.survey.active.randomData && response.data[0].length) {
          //Init columns
          const randomDataColumns = response.data[0].slice(1);

          //> calculate mean for every axes from the topics
          if (currentResultsType === "axes") {
            const axes = props.axis.list;
            const axesData: any = [];
            let axisValues: any[] = [];
            axes.forEach((axis) => {
              //Get all data for each list of topics for the axis
              const topicAxes = props.topic.list
                .filter((x) => x.AxisId === axis.id)
                .map((x) => x.id);
              const topicAxesData = response.data.filter(
                (x) => topicAxes.indexOf(x[0]) > -1
              );

              //Init array of values
              axisValues = new Array(randomDataColumns.length);

              //Push values in array
              randomDataColumns.forEach((col, i) => {
                topicAxesData.forEach((line) => {
                  if (axisValues.length) {
                    axisValues[i].push(line[i + 1]);
                  }
                });
              });

              //Get mean and add to array
              axisValues = axisValues.map((x) => (x = mean(x)));
              axesData.push([topicAxes[0]].concat(axisValues));
            });

            response.data = response.data.slice(0, 3).concat(axesData);

          }

          //> calculate global mean for every population
          if (currentResultsType === "survey") {
            const surveyValues: any[] = [];

            //Init values with empty array
            randomDataColumns.forEach((col, i) => {
              surveyValues[i] = [];
            });

            //Add all values by columns in order to calculate mean
            response.data.slice(2).forEach((line) => {
              line.slice(1).forEach((item, i) => {
                surveyValues[i].push(item);
              });
            });

            //Calculate mean
            const surveyValuesMeans: any[] = [response.data[2][0]];
            surveyValues.forEach((values) => {
              surveyValuesMeans.push(mean(values));
            });

            //Erase data values
            response.data = response.data
              .slice(0, 2)
              .concat([surveyValuesMeans]);
          }
        }

        //Get participation for attributes
        const newParticipations: any = [];
        if (response.data[0]) {
          //Get count of users that have participate (first row)
          response.data[0].slice(FIRST_COLUMN_INDEX).forEach((item) => {
            newParticipations.push({
              done: item,
            });
          });

          //Get count of the total (second row)
          //Calculate participation
          if (response.data[1]) {
            response.data[1].slice(FIRST_COLUMN_INDEX).forEach((item, i) => {
              newParticipations[i].total = item;
            });
          }
        }

        //Add participation for the selected filter
        if (response.populationParticipation && !hideFirstColumn) {
          const populationParticipation = response.populationParticipation;
          newParticipations.push({
            done: props._session.dashboardDisplaySettings
              .hideStartedForParticipation
              ? populationParticipation.participation_done
              : populationParticipation.participation_done +
                populationParticipation.participation_started,
            total: populationParticipation.participation_send,
          });
        }

        //Add company participation results
        if (response.companyParticipation) {
          const companyParticipation = response.companyParticipation;
          newParticipations.push({
            done: props._session.dashboardDisplaySettings
              .hideStartedForParticipation
              ? companyParticipation.participation_done
              : companyParticipation.participation_done +
                companyParticipation.participation_started,
            total: companyParticipation.participation_send,
          });
        }

        //Update ratio
        newParticipations.forEach((item) => {
          item.ratio = Math.round((item.done / item.total) * 100);
        });

        //Update item in store
        setParticipations(newParticipations);

        // Format array data that will be displayed
        // Start at row 2 (FIRST_LINE_INDEX)
        const data:any = []

        response.data
          .slice(!props.isGollac ? FIRST_LINE_INDEX : 0)
          .forEach((item) => {
            //Init label and header
            let axisIndex = -1;
            let topicOrder = 0;
            let label = "";
            let headerLabel = "";
            let headerColor = "";
            let headerSubtitle = "";

            //Skip row (for secondary questions)
            let skip = false

            //For axis edit aid to match with a topic
            if (currentResultsType === "axes"){
              item[0] = axisFindTopicAid(item[0])
            }

            //Id is the first item of the row
            const aid = item[0];

            //If aid === 0 it is company name
            if (aid === 0) {
              label = props.survey.active.name;
            }

            //Get all values for one line
            const values: any[] = item.slice(FIRST_COLUMN_INDEX);

            //Get value for the filter
            if (response.population && !hideFirstColumn) {
              const populationItem = response.population.find(
                (x) => x.aid === aid
              );
              const populationValue = populationItem
                ? populationItem.note
                : null;
              values.push(populationValue);
            }

            //Get value for the company
            if (response.company) {
              const companyItem = response.company.find((x) => x.aid === aid);
              const companyValue = companyItem ? companyItem.note : null;
              values.push(companyValue);
            }

            //Determine label
            //If survey name of the survey
            let topic;
            if (currentResultsType === "survey") {
              label = props.survey.active.name;
            }
            //For axis : search axis from the topic
            //For topic get label of the topic
            else {
              if (currentResultsType === "questions") {
                topic = props.topic.list.find(
                  (x) => x.Questions.map((x) => x.aid).indexOf(aid) > -1
                );
              } else {
                topic = props.topic.list.find(
                  (x) => (props.survey.active.randomData ? x.id : x.aid) === aid
                );
              }

              if (topic) {
                const axis = topic.Axis ? new Axis(topic.Axis) : null;
                axisIndex = axis
                  ? props.axis.list.findIndex((x) => x.id === axis.id)
                  : 99;
                topicOrder = props.topic.list.findIndex(
                  (x) => x.id === topic.id
                );

                //Define label
                if (currentResultsType === "axes") {
                  label = axis ? axis.label : t("questions_without_axis");
                } else if (currentResultsType === "topics") {
                  label = topic.label;
                } else if (topic.Questions.length) {
                  const question = questions.find((x) => x.aid === aid);
                  if (question){
                    label = question.label;
                    skip = question.primaryId !== null
                  }else{
                    label = ""
                    skip = true
                  }
                }

                //Define header
                if (
                  currentResultsType === "topics" ||
                  currentResultsType === "questions"
                ) {
                  headerLabel = axis ? axis.label : "";
                  headerSubtitle =
                    currentResultsType === "questions" ? topic.label : "";
                  headerColor = axis ? axis.color : "";
                }
              }
            }

            //Set label for eacg line and init value array
            const line: any = {
              axisIndex,
              topicOrder,
              headerLabel,
              headerColor,
              headerSubtitle,
              label,
              values: [],
            };

            //Format data for the heatmap
            //if value is less than 10 add one decimal
            values.forEach((value, i) => {
              let label = null;
              if (value) {
                label =
                  props._session.dashboardDisplaySettings
                    .dashboardDisplayMode === "note"
                    ? (value / 10).toFixed(value < 100 ? 1 : 0)
                    : value.toFixed(0);
              }

              // Initialize the object to push
              const dataObject: {
                note: number | null;
                label: string | null;
                gollacField?: string;
              } = {
                note: value,
                label,
              };

              // Add gollacField only if props.isGollac is true
              if (props.isGollac) {
                switch (i) {
                  case 0:
                    dataObject.gollacField = "employee";
                    break;
                  case 1:
                    dataObject.gollacField = "gap";
                    // Assign note from index 3 to dataObject.note and move current note to label
                    dataObject.label = dataObject.note
                      ? dataObject.note.toString()
                      : null;
                    dataObject.note = values[3] ?? null; // Assign value from index 3
                    break;
                  case 2:
                    dataObject.gollacField = "worst";
                    break;
                  case 3:
                    return;
                }
              }

              line.values.push(dataObject);
            });

            //Add company value (in order to sort array and to compare for the color)
            line.companyValue = line.values[line.values.length - 1].note;

            //Custom color mode
            //Set color according values
            const notes = line.values.map((x) => x.note);
            line.values.forEach((value) => {
              const note = value.note;

              if (note >= props._session.dashboardDisplaySettings.dashboardNightmareModeMax) {
                value.color =
                  max(notes) === note &&
                  !props._session.dashboardDisplaySettings
                    .dashboardNightmareModeHeatmap
                    ? "#51e898"
                    : colorGreen;
              } else if (note < props._session.dashboardDisplaySettings.dashboardNightmareModeMin) {
                value.color =
                  min(notes) === note &&
                  !props._session.dashboardDisplaySettings
                    .dashboardNightmareModeHeatmap
                    ? "#f05146"
                    : colorRed;
              } else {
                //Detect if color is grayscale
                //If it is the case do not apply color
                if (
                  props._session.dashboardDisplaySettings
                    .dashboardNightmareModeHeatmap
                ) {
                  const color =
                    props._session.dashboardDisplaySettings
                      .dashboardNightmareColor2;
                  color.slice(1);
                  const colorBlocks: string[] = [];
                  for (let i = 1; i < color.length - 1; i++) {
                    if (i % 2 === 1) {
                      colorBlocks.push(color[i] + color[i + 1]);
                    }
                  }

                  if (uniq(colorBlocks).length > 1) {
                    value.color =
                      props._session.dashboardDisplaySettings.dashboardNightmareColor2;
                  }
                }
              }
            });

            if (!skip){
              data.push(line);
            }
            
          });

        //Update state
        if (newColumns.length > 10) {
          setFirstColumnWidth(320);
        } else if (newColumns.length > 15) {
          setFirstColumnWidth(280);
        } else {
          setFirstColumnWidth(400);
        }

        if (props.isGollac) {
          const translateColsName: string[] = newColumns.map((col: string) =>
            t(col)
          );
          setColumns(translateColsName);
        } else {
          setColumns(newColumns);
        }

        // setColumns(newColumns);
        setColumnsTextLength(Math.max(...newColumns.map((x) => x.length)));
        setData(
          !props.isGollac
            ? orderBy(
                data.filter((x) => x.label !== ""),
                [
                  "axisIndex",
                  "topicOrder",
                  "headerColor",
                  "headerSubtitle",
                  "label",
                ]
              )
            : data
        );

        store.dispatch(reportEdit("heatmapLength", data.length));
        //Reset params
        window.history.pushState({}, "", window.location.pathname);
      }

      setIsLoading(false);

    }

    load();
    setCurrentModal(null);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter.dashboard, currentFilter, currentResultsType]);

  //Click column to display tree
  //If default attribute search attribute aid
  async function clickColumn(column) {
    const dashboardFilters = new DashboardFilters();

    //Behavior for default filters
    //Find id from the matching configuration list
    if (currentFilter.default) {
      if (currentFilter.id === "gender") {
        const gender = USER_LIST_GENDER.find((x) => t(x.name) === column);
        dashboardFilters.gender = gender ? gender.id : null;
      } else if (currentFilter.id === "birth_date") {
        const birthDate: UserFilterDates | undefined = getUserFilterDates(
          "BIRTH_DATE",
          props.survey.active.dateStart
        ).find((x: UserFilterDates) => t(x.name) === column);
        dashboardFilters.birthDate = birthDate ? birthDate.id : null;
      } else if (currentFilter.id === "company_welcome_date") {
        const companyWelcomeDate: UserFilterDates | undefined =
          getUserFilterDates(
            "COMPANY_WELCOME_DATE",
            props.survey.active.dateStart
          ).find((x: UserFilterDates) => t(x.name) === column);
        dashboardFilters.companyWelcomeDate = companyWelcomeDate
          ? companyWelcomeDate.id
          : null;
      }
    }
    //For custom filter
    else {
      //Search matching attribute for random data according the name
      if (props.survey.active.randomData) {
        const attribute = DEFAULT_RANDOM_ATTRIBUTES.find(
          (x) => t(x.name) === column
        );
        if (attribute) {
          dashboardFilters.customFilters = [
            new FilterLite({ id: "random", attributesIds: [attribute.id] }),
          ];
        }
      }
      //Search in the list of attributes from the database
      else {
        //Search filterId
        const searchedFilter = props.filter.list.find(
          (x) => x.name === currentFilter.name
        );
        if (searchedFilter) {
          setCurrentModal(MODAL_LOADING);
          const attributesResponse: any = await store.dispatch(
            fetchAttributes(new DashboardFilters(), currentFilter.name)
          );
          if (!attributesResponse.error) {
            const attribute = attributesResponse.find((x) => x.name === column);
            if (attribute) {
              dashboardFilters.customFilters = [
                new FilterLite({
                  id: searchedFilter.id,
                  attributesIds: [attribute.aid.toString()],
                }),
              ];
            } else {
              setCurrentModal(null);
            }
          }
        }
      }
    }

    //Redirect
    setTimeout(() => {
      store.dispatch(filterReplaceDashboard(dashboardFilters));
      navigate("/dashboard/overview");
    }, 200);
  }

  //Get classname for a cell
  function getCellClassName(index, isParticipation) {
    let className = "heatmap-cell rel flex flex-dcol";

    if (isParticipation) {
      className += " grey-t";
    }

    if (showCompany && showPopulation && index === columns.length - 2) {
      className += " heatmap-cell-company";
    }

    if (!showPopulation && showCompany && index === columns.length - 1) {
      className += " heatmap-cell-company";
    }

    if (showPopulation && !showCompany && index === columns.length - 1) {
      className += " heatmap-cell-company";
    }

    return className;
  }

  //Classname depend if bookmark are defined
  function getClassName() {
    let className = "heatmap-container";
    if (props.isGollac) {
      className += " heatmap-gollac";
    } else {
      if (props.bookmark.list.length > 0) {
        className += " heatmap-container-bookmark";
      }
    }

    return className;
  }

  //Get style for the column
  function getColumnStyle(index) {
    let marginLeft;

    if (showCompany && showPopulation && index === columns.length - 2) {
      marginLeft = 60;
    }

    if (!showPopulation && showCompany && index === columns.length - 1) {
      marginLeft = 60;
    }

    if (showPopulation && !showCompany && index === columns.length - 1) {
      marginLeft = 60;
    }

    return {
      marginLeft,
    };
  }

  //Get all rows
  //Apply restriction if preview
  function getRows() {
    if (props.isPreview && data.length) {
      const offset = props.offset ? props.offset + 1 : 1;
      const limit = props.limit ? props.limit : 0;

      return [data[0]].concat(data.slice(offset, offset + limit));

      //data.slice(props.offset, props.limit && props.offset ? props.offset + props.limit + 1 : undefined)
    } else {
      return data
        .filter((item) => {
          const searchValue = searchTerm.toLowerCase();
          return (
            item.label.toLowerCase().includes(searchValue) ||
            item.headerSubtitle?.toLowerCase().includes(searchValue) ||
            item.headerLabel?.toLowerCase().includes(searchValue)
          );
        })
        .map((item) => ({
          ...item,
          label: highlightText(item.label, searchTerm),
          headerSubtitle: highlightText(item.headerSubtitle ?? "", searchTerm),
          headerLabel: highlightText(item.headerLabel ?? "", searchTerm),
        }));
    }
  }

  // sorting columns
  const handleSort = (isNumeric: boolean = false, index?: number | string) => {
    setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));

    // Set the active column index for highlighting the clicked column
    if (index !== undefined) {
      setActiveColumnIndex(index);
    }

    // Ignore the first item for sorting
    const sorted = [
      data[0],
      ...data.slice(1).sort((a, b) => {
        let aValue: number | string, bValue: number | string;

        // Sorting by topicOrder
        aValue = a.topicOrder;
        bValue = b.topicOrder;

        // If sorting by numeric values
        if (isNumeric && index !== undefined) {
          aValue = a.values[index].note;
          bValue = b.values[index].note;
        }

        // Sorting logic based on the current sortOrder
        return sortOrder === "asc"
          ? aValue > bValue
            ? 1
            : -1
          : aValue < bValue
          ? 1
          : -1;
      }),
    ];

    setData(sorted);
  };

  // highlight searching text
  function highlightText(text: string, searchTerm: string) {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  }

  //Init filters
  function initCurrentFilter() {
    if (props.survey.active.randomData) {
      return new Filter(initFilters().reverse()[0]);
    } else {
      const filterName: string =
        props.isGollac && props._session.accountOptions.gollacFilterName
          ? props._session.accountOptions.gollacFilterName
          : props.filterName
          ? props.filterName
          : props._session.accountOptions.heatmapFilterName
          ? props._session.accountOptions.heatmapFilterName
          : "company";

      const customFilter: Filter | undefined = props.filter.list.find(
        (x: Filter) => x.name === filterName
      );

      const defaultFilter: any | undefined = DEFAULT_FILTERS.find(
        (x) => x.id === filterName
      );

      const companyFilter: Filter = new Filter({
        id: "company",
        name: "user_company_alt",
        default: true,
      });

      if (defaultFilter) {
        return new Filter(defaultFilter);
      } else if (customFilter) {
        return new Filter(customFilter);
      } else {
        return companyFilter;
      }
    }
  }

  //Init results type
  function initCurrentResultsType() {
    const currentResultsType: AccountHeatmapResult | undefined =
      props.resultsType
        ? ACCOUNT_RESULTS_TYPES.find(
            (x: AccountHeatmapResult) => x === props.resultsType
          )
        : undefined;
    if (currentResultsType) {
      return currentResultsType;
    } else {
      return props._session.accountOptions.heatmapResultsType
        ? props._session.accountOptions.heatmapResultsType
        : ACCOUNT_RESULTS_TYPES[0];
    }
  }

  //Is dot visible
  function isDotVisible() {
    const noFilter = isEqual(
      new DashboardFilters(props.filter.dashboard),
      new DashboardFilters()
    );
    return !noFilter;
  }

  //Select filter to display
  function selectFilter(filter: Filter) {
    setCurrentFilter(filter);
  }

  //Select type of results to display
  function selectResultsType(resultsType: AccountHeatmapResult) {
    setCurrentResultsType(resultsType);
  }

  return (
    <div>
      {currentModal === MODAL_LOADING && <LoadingModal />}

      {currentModal === MODAL_HELP && (
        <HeatmapHelp
          onClose={() => setCurrentModal(null)}
          resultsTypes={ACCOUNT_RESULTS_TYPES.map(
            (x: AccountHeatmapResult) => ({ id: x, name: x })
          )}
        />
      )}

      {currentModal === MODAL_FILTERS && (
        <Modal
          isCloseButtonVisible
          title={t("filters")}
          onClose={() => setCurrentModal(null)}
        >
          <div
            style={{
              marginRight: "160px",
              marginBottom: "290px",
            }}
          >
            <DashboardFiltersWidget
              currentFilters={props.filter.list}
              currentSurvey={props.survey.active}
            />
          </div>
        </Modal>
      )}

      {props.filter.status === INSUFFICIENT_PARTICIPATION ? (
        <div style={{ width: "100vw" }}>
          <NoResultsWidget />
          {!isEqual(
            new DashboardFilters(props.filter.dashboard),
            new DashboardFilters()
          ) && (
            <div className="flex">
              <Space />
              <Button
                className="primary"
                onClick={() =>
                  store.dispatch(filterReplaceDashboard(new DashboardFilters()))
                }
              >
                {t("utils_back")}
              </Button>
              <Space />
            </div>
          )}
        </div>
      ) : isLoading ? (
        <div className="flex1 header-loader">
          <PageLoader status={STATUS_LOADING} />
        </div>
      ) : (
        <div className={props.isPreview ? "" : getClassName()}>
          <Card
            isNotCardClass={props.isGollac ? true : undefined}
            isWithoutPadding
            exportName={t(currentFilter.name)}
            isOverflowHidden={!props.isPreview}
          >
            <table
              id={props.id ? props.id : "heatmap-table"}
              style={{
                width: props.isGollac ? "100%" : "",
              }}
            >
              <thead
                className={
                  "heatmap-header " + (props.isPreview ? "" : "lightwhite")
                }
                style={{
                  display: "block",
                  marginTop: props.bookmark.list.length > 1 ? 43 : 0,
                }}
              >
                <tr className="flex" style={{ height: 130, paddingLeft: 32 }}>
                  <th
                    scope="col"
                    className="flex flex-dcol"
                    style={{
                      padding: "24px 8px",
                      minWidth: firstColumnWidth,
                      fontWeight: "initial",
                      position: "sticky",
                      top: 0,
                      left: 0,
                      zIndex: 3,
                    }}
                  >
                    <div className="dashboard-heatmap-separator" />

                    {!props.isPreview && !props.isGollac && (
                      <div
                        className="abs lightwhite flex"
                        style={{ padding: 4 }}
                      >
                        <ListButton
                          onClick={() => setCurrentModal(MODAL_HELP)}
                          text={t("utils_params")}
                          icon={faCog}
                        />
                        <ListButton
                          dot={isDotVisible()}
                          onClick={() => setCurrentModal(MODAL_FILTERS)}
                          text={t("filters")}
                          icon={faFilter}
                        />
                      </div>
                    )}

                    <div className="flex">
                      <Space />
                      <div
                        className="dashboard-heatmap-filter-name"
                        id={
                          currentFilter.default
                            ? currentFilter.id
                            : currentFilter.name
                        }
                      >
                        {props.isPreview ? (
                          <div style={{ fontWeight: 700 }}>
                            {t(
                              currentFilter.default
                                ? "user_" + currentFilter.id + "_alt"
                                : currentFilter.name
                            )}
                          </div>
                        ) : !props.isGollac ? (
                          <ListDropdown
                            active={currentFilter.id}
                            isBold
                            isCaretAlwaysVisible
                            onSelect={selectFilter}
                            value={t(
                              currentFilter.default
                                ? "user_" + currentFilter.id + "_alt"
                                : currentFilter.name
                            )}
                            values={filters}
                          />
                        ) : null}
                      </div>
                    </div>

                    <Space />

                    <div className="">
                      <div
                        className="dashboard-heatmap-results-type flex1 flex flex-dcol"
                        style={{ textAlign: "left" }}
                        id={currentResultsType}
                      >
                        <Space />
                        {props.isPreview ? (
                          <div style={{ fontWeight: 700 }}>
                            {t(currentResultsType)}
                          </div>
                        ) : (
                          <ListDropdown
                            active={currentResultsType}
                            isBold
                            isCaretAlwaysVisible
                            onSelect={(type: any) => selectResultsType(type.id)}
                            value={t(currentResultsType)}
                            values={ACCOUNT_RESULTS_TYPES.map(
                              (x: AccountHeatmapResult) => ({ id: x, name: x })
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </th>

                  {
                    // display all columns (attributes start at 1)
                    data.length > 0 &&
                      columns.map((column, c) => (
                        <th
                          scope="col"
                          key={c}
                          className={
                            !props.isGollac
                              ? "heatmap-column heatmap-cell"
                              : "heatmap-column heatmap-cell heatmap-cell-company"
                          }
                          style={{
                            ...getColumnStyle(c),
                            fontWeight: "initial",
                            display:
                              column === "gollac_delta" ? "none" : "flex",
                          }}
                        >
                          <div className="heatmap-column-text flex">
                            <span
                              className="_hover"
                              style={{
                                fontSize: columnsTextLength > 32 ? 10 : 12,
                              }}
                              onClick={() => {
                                if (!props.isGollac) clickColumn(column);
                              }}
                            >
                              {column}
                            </span>
                          </div>
                        </th>
                      ))
                  }

                  <th scope="col" className="heatmap-column heatmap-cell" />
                </tr>
              </thead>

              {data.length > 0 ? (
                <tbody
                  className="heatmap-data"
                  style={{
                    backgroundColor: props.isPreview ? undefined : "white",
                    display: "block",
                  }}
                >

                  { !props.isPreview &&
                    <tr
                    style={{
                      paddingTop: "inherit",
                      paddingBottom: "inherit",
                    }}
                    className={"flex flex1 list-item"}
                  >
                    <th
                      scope="row"
                      style={{
                        textAlign: "left",
                        position: "sticky",
                        left: 0,
                        backgroundColor: props.isPreview ? undefined : "white",
                        zIndex: 1,
                        fontWeight: "initial",
                        width: firstColumnWidth,
                        padding: "0px 8px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        style={{ marginTop: -12, marginBottom: 0 }}
                        margin="dense"
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        label={`${t("utils_search")}...`}
                        variant="standard"
                      />
                      <Box className="_hover"
                        sx={{ ml: "auto" }}
                        onClick={() => handleSort(false, "firstCol")}
                      >
                        <FontAwesomeIcon
                          icon={faUndo}
                          style={{
                            fontSize: "13px",
                            padding: 5,
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </th>

                    {
                      // display all columns to sort
                      data.length > 0 &&
                        columns.map((column, c) => {
                          // Condition to exclude the last two filters if isGollac is true
                          if (props.isGollac && c >= columns.length - 2) {
                            return null; // Ignore the last two columns
                          }

                          return (
                            <th
                              key={`sort-index-${c}`}
                              scope="row"
                              className={
                                !props.isGollac
                                  ? ""
                                  : "heatmap-column heatmap-cell heatmap-cell-company"
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box className="_hover"
                                sx={{
                                  color:
                                    activeColumnIndex === c
                                      ? "primary.main"
                                      : "info.main",
                                  maxWidth: 52,
                                  minWidth: "52px",
                                  textAlign: "center",
                                  ml:
                                    getCellClassName(c, false) ===
                                    "heatmap-cell rel flex flex-dcol heatmap-cell-company"
                                      ? "60px"
                                      : undefined,
                                }}
                                onClick={() => handleSort(true, c)}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    sortOrder === "asc" &&
                                    activeColumnIndex === c
                                      ? faSortAmountUp
                                      : faSortAmountDownAlt
                                  }
                                  style={{
                                    fontSize: "13px",
                                    padding: 5,
                                    cursor: "pointer",
                                  }}
                                />
                              </Box>
                            </th>
                          );
                        })
                    }
                  </tr>
                  }

                  {
                    /** loop on each row */
                    getRows().map((item: any, i: number) => (
                      <tr key={i}
                        style={{ borderTop :  i <= 1 ? "1px solid white" : undefined }}
                        className={
                          "flex flex1 list-item " +
                          (props.isPreview && item.headerLabel.length > 0
                            ? ""
                            : "list-item-large")
                        }
                      >
                        <th
                          scope="row"
                          style={{
                            textAlign: "left",
                            position: "sticky",
                            left: 0,
                            backgroundColor: props.isPreview
                              ? undefined
                              : "white",
                            zIndex: 1,
                            fontWeight: "initial",
                            width: firstColumnWidth,
                            padding: "3px 8px",
                          }}
                        >
                          {item.headerLabel && (
                            <div
                              style={{
                                fontSize: "12px",
                                color: item.headerColor,
                              }}
                            >
                              {item.headerSubtitle && (
                                <span>
                                  <b>{item.headerSubtitle}</b> -&nbsp;
                                </span>
                              )}
                              {item.headerLabel}
                            </div>
                          )}

                          <div>
                            {
                              //set in bold survey name row
                              i === 0 &&
                              !props.isGollac &&
                              searchTerm === "" ? (
                                <b>{item.label}</b>
                              ) : (
                                item.label
                              )
                            }
                          </div>
                        </th>

                        {item.values.map((value: any, j: number) => (
                          <td
                            style={{
                              width: 50,
                            }}
                            className={
                              !props.isGollac
                                ? getCellClassName(j, false)
                                : `${getCellClassName(
                                    j,
                                    false
                                  )} heatmap-cell-company`
                            }
                            key={j}
                          >
                            <Space />

                            {value.label ? (
                              value.color && !props.isGollac ? (
                                <div
                                  className="flex"
                                  style={{
                                    maxWidth: 52,
                                  }}
                                >
                                  <Space />
                                  <Chip
                                    color={value.color}
                                    onClick={() =>
                                      setCurrentCellOpen([true, i, j])
                                    }
                                  >
                                    {value.label}
                                  </Chip>
                                  <Space />
                                </div>
                              ) : !props.isGollac ? (
                                <b
                                  className="grey-t _hover"
                                  onClick={() =>
                                    setCurrentCellOpen([true, i, j])
                                  }
                                >
                                  {value.label}
                                </b>
                              ) : // gollacCase
                              value.gollacField === "employee" ? (
                                <Chip
                                  onClick={() =>
                                    setCurrentCellOpen([true, i, j])
                                  }
                                >
                                  {value.label}
                                </Chip>
                              ) : value.gollacField === "gap" ? (
                                value.label === "negligate" ? (
                                  <WbSunnyIcon color="success" sx={{ ml: 2 }} />
                                ) : value.label === "moderate" ? (
                                  <WbCloudyIcon
                                    color="warning"
                                    sx={{ ml: 2 }}
                                  />
                                ) : (
                                  value.label === "critical" && (
                                    <ThunderstormIcon
                                      color={i !== 0 ? "error" : undefined}
                                      sx={{
                                        ml: 2,
                                        color:
                                          i === 0
                                            ? "idt.red.darker"
                                            : undefined,
                                      }}
                                    />
                                  )
                                )
                              ) : value.gollacField === "worst" ? (
                                <b
                                  style={{ width: "140px" }}
                                  className="grey-t"
                                >
                                  {t(value.note)}
                                </b>
                              ) : null
                            ) : (
                              !props.isGollac && (
                                <div className="_hover">
                                  <FontAwesomeIcon
                                    icon={faBan}
                                    className="grey-t"
                                    onClick={() =>
                                      setCurrentCellOpen([true, i, j])
                                    }
                                  />
                                </div>
                              )
                            )}

                            {
                              /** display help on clic on cell */
                              currentCellOpen[0] === true &&
                                currentCellOpen[1] === i &&
                                currentCellOpen[2] === j && (
                                  <ContextMenu
                                    onClose={() =>
                                      setCurrentCellOpen([false, 0, 0])
                                    }
                                  >
                                    <ListItem>
                                      {value.label
                                        ? t(
                                            "dashboard_heatmap_help_" +
                                              currentResultsType +
                                              "_" +
                                              props._session
                                                .dashboardDisplaySettings
                                                .dashboardDisplayMode,
                                            {
                                              note: value.label,
                                              attribute: columns[j],
                                              templateItem: item.label,
                                            }
                                          )
                                        : t("survey_no_results_help", {
                                            count:
                                              props._session.participationMin,
                                          })}
                                    </ListItem>
                                  </ContextMenu>
                                )
                            }

                            <Space />
                          </td>
                        ))}

                        {i === 0 && searchTerm === "" && (
                          <td
                            style={{
                              height: 1,
                              borderBottom: "1px solid #8c8c8c",
                              width: "100%",
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                            }}
                          />
                        )}
                      </tr>
                    ))
                  }

                  {props._session.accountOptions.heatmapDisplayParticipation &&
                    !props.isGollac && (
                      <tr className="flex flex1 list-item list-item-large">
                        <th
                          scope="row"
                          style={{
                            textAlign: "left",
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backgroundColor: props.isPreview
                              ? undefined
                              : "white",
                            fontWeight: "initial",
                            width: firstColumnWidth,
                            padding: "3px 8px",
                          }}
                        >
                          <b>{t("survey_participation")}</b>
                        </th>

                        {participations.map((item, i) => (
                          <td className={getCellClassName(i, true)} key={i}>
                            <span>
                              <b>{item.ratio}</b>%
                            </span>
                            <br />
                            <span style={{ fontSize: "10px" }}>
                              {item.done} / {item.total}
                            </span>
                          </td>
                        ))}
                      </tr>
                    )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>
                      <PagePlaceholder
                        title={t("dashboard_heatmap_empty")}
                        image="sherlock"
                      />
                    </td>
                  </tr>
                </tbody>
              )}

              <tfoot className="height-60" />
            </table>
          </Card>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: StateProps) => ({
  _session: state._session,
  attribute: state.attribute,
  axis: state.axis,
  bookmark: state.bookmark,
  filter: state.filter,
  survey: state.survey,
  project: state.project,
  topic: state.topic,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardHeatmapWidget)
);
