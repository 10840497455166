/** 
 * SUPERVISOR.DROPDOWN
 * Select supervisor for an account
 */
import Dropdown from "@/components/dropdown"
import { store } from "@/index"
import { connect } from "react-redux"
import { AccountState } from "@/redux/account.types"
import { WithTranslation, withTranslation } from "react-i18next"
import { accountEdit } from "@/redux/account.actions"
import { Session } from "@/redux/_session.types"
import { Supervisor, SupervisorState } from "@/redux/supervisor.types"

interface StateProps extends WithTranslation{
  _session : Session
  account : AccountState
  supervisor : SupervisorState
}

interface OwnProps{
  isSales? : boolean
  onSave : Function
}

type Props = StateProps & OwnProps 

function SupervisorDropdown(props:Props) {
  const { t } = props

  //Select supervisor and edit account
  function selectSupervisor(supervisor:Supervisor | null){
    if (props.isSales){
      store.dispatch(accountEdit("SupervisorSales", supervisor))
      store.dispatch(accountEdit("SupervisorIdSales", supervisor ? supervisor.id : null))
    }else{
      store.dispatch(accountEdit("Supervisor", supervisor))
      store.dispatch(accountEdit("SupervisorId", supervisor ? supervisor.id : null))
    }
  }

  //Save changes
  function saveSupervisor(){
    props.onSave()
  }

  //Get supervisor
  function getSupervisor(){
    return props.isSales ? props.account.active.SupervisorSales : props.account.active.Supervisor
  }

  return (
    <div>
      <Dropdown active={getSupervisor()?.id}
        displayField="name"
        list={props.supervisor.list}
        isNullAllowed
        title={props.isSales ? t("supervisor_sales") : t("supervisor")}
        onSave={saveSupervisor}
        onSelect={selectSupervisor}
        status={props.account.status}
        value={getSupervisor()?.name}
      />
    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  account : state.account,
  supervisor : state.supervisor
})

export default connect(mapStateToProps)(withTranslation()(SupervisorDropdown))