import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    idt: {
      grey: PaletteColor;
      green: PaletteColor;
      red: PaletteColor;
      orange: PaletteColor;
    };
  }

  interface PaletteOptions {
    idt?: {
      grey?: IdtPaletteColorOptions;
      green?: IdtPaletteColorOptions;
      red?: IdtPaletteColorOptions;
      orange?: IdtPaletteColorOptions;
    };
  }

  interface PaletteColor {
    lighter?: string;
    light: string;
    main: string;
    dark: string;
    darker?: string;
    contrastText: string;
  }

  interface IdtPaletteColorOptions {
    lighter?: string;
    light?: string;
    main?: string;
    dark?: string;
    darker?: string;
    contrastText?: string;
  }

  interface Theme {
    fontFamily: string[];
  }

  interface ThemeOptions {
    fontFamily?: string[];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    primary: true;
    secondary: true;
    idt: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    primary: true;
    secondary: true;
    idt: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      light: "#00c2e0",
      main: "#00c2e0",
      dark: "#0082a0",
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#ff9500",
      main: "#ff9500",
      dark: "#ff7500",
      contrastText: "#FFFFFF",
    },
    error: {
      light: "#EB6B54",
      main: "#eb5a46",
      dark: "#D4513F",
      contrastText: "#FFFFFF",
    },
    info: {
      light: "#F9FBFC",
      main: "#CCCCCC",
      dark: "#8C8C8C",
      contrastText: "#FFFFFF",
    },
    success: {
      light: "#20CA7E",
      main: "#1CB06E",
      dark: "#1CB06E",
      contrastText: "#FFFFFF",
    },
    warning: {
      light: "#ff9500",
      main: "#ff9500",
      dark: "#ff7500",
      contrastText: "#FFFFFF",
    },
    idt: {
      grey: {
        lighter: "#FFFFFF",
        light: "#F9FBFC",
        main: "#CCCCCC",
        dark: "#8C8C8C",
        darker: "#111C2B",
        contrastText: "#FFFFFF",
      },
      green: {
        lighter: "#9BEBC7",
        light: "#20CA7E",
        main: "#1CB06E",
        dark: "#1CB06E",
        darker: "#0B4F30",
        contrastText: "#FFFFFF",
      },
      red: {
        lighter: "#FF745C",
        light: "#EB6B54",
        main: "#eb5a46",
        dark: "#D4513F",
        darker: "#B84637",
        contrastText: "#FFFFFF",
      },
      orange: {
        lighter: "#FFE1B2",
        light: "#FFB74D",
        main: "#ff9500",
        dark: "#CC7600",
        darker: "#663B00",
        contrastText: "#FFFFFF",
      },
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
});
