import {
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import WbCloudyIcon from "@mui/icons-material/WbCloudy";
import { t } from "i18next";
import { Filter, FilterPopulation } from "@/redux/filter.types";
import { AccountOptions } from "@/redux/account.types";
import { filterStatus } from "@/redux/filter.actions";
import { sessionEditAccountOptions } from "@/redux/_session.actions";
import { store } from "@/index";
import {
  STATUS_SAVE_ERROR,
  STATUS_SAVED,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { accountUpdateOptions } from "@/redux/account.actions";
import { selectFilter } from "@/core/slices/filter/filter.selector";
import { selectSession } from "@/core/slices/session/session-selector";

export const GollacFiltersWidget = () => {
  const filter = useSelector(selectFilter);
  const session = useSelector(selectSession);

  const handleSetGollacParams = () => {
    editReferenceGollacParams();
  };

  // GollacParams
  const [gollacParams, setGollacParams] = useState<Partial<AccountOptions>>({
    gollacFilterName: session.accountOptions.gollacFilterName,
    gollacManagerAttributeName:
      session.accountOptions.gollacManagerAttributeName,
    ceilsGollacMinValue: session.accountOptions.ceilsGollacMinValue,
    ceilsGollacMaxValue: session.accountOptions.ceilsGollacMaxValue,
  });

  // Function to validate and adjust the value
  const validateAndAdjustValue = (
    value: string,
    referenceValue: number,
    isMinValue: boolean
  ): number => {
    let parsedValue = parseFloat(value);

    // Limiter la valeur à 10
    if (parsedValue > 100) {
      parsedValue = 100;
    }

    // Pour minValue: s'assurer qu'elle n'excède pas maxValue - 0.1
    if (isMinValue && parsedValue > referenceValue - 1) {
      parsedValue = referenceValue - 1;
    }

    // Pour maxValue: s'assurer qu'elle n'est pas inférieure à minValue + 0.1
    if (!isMinValue && parsedValue < referenceValue + 1) {
      parsedValue = referenceValue + 1;
    }

    // Forcer un seul chiffre après la virgule
    return Math.round(parsedValue * 10) / 10;
  };

  const handleBlurGollacParams = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    let value = event.target.value;

    // Si l'utilisateur laisse le champ vide ou efface la valeur
    if (isNaN(parseFloat(value)) || value === "") {
      if (type === "ceilsGollacMinValue") {
        // Si c'est minValue, utilise une valeur par défaut raisonnable
        value = (gollacParams.ceilsGollacMaxValue! - 0.1).toString();
      } else if (type === "ceilsGollacMaxValue") {
        // Si c'est maxValue, utilise minValue + 0.1
        value = (gollacParams.ceilsGollacMinValue! + 0.1).toString();
      }
    } else {
      // Ajuster la valeur en fonction du type (min ou max)
      if (type === "ceilsGollacMinValue") {
        value = validateAndAdjustValue(
          value,
          gollacParams.ceilsGollacMaxValue!,
          true // C'est une minValue
        ).toString();
      } else if (type === "ceilsGollacMaxValue") {
        value = validateAndAdjustValue(
          value,
          gollacParams.ceilsGollacMinValue!,
          false // C'est une maxValue
        ).toString();
      }
    }

    // Appliquer la nouvelle valeur après le blur
    handleChangeGollacParams(
      { ...event, target: { ...event.target, value } },
      type
    );
  };

  type GollacEvent =
    | React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    | SelectChangeEvent<string | null>;

  // Ensuite, modifie les appels de la fonction pour différencier minValue et maxValue :
  const handleChangeGollacParams = (event: GollacEvent, type: string) => {
    const updatedParams = {
      gollacFilterName: gollacParams.gollacFilterName,
      gollacManagerAttributeName: gollacParams.gollacManagerAttributeName,
      ceilsGollacMinValue: gollacParams.ceilsGollacMinValue,
      ceilsGollacMaxValue: gollacParams.ceilsGollacMaxValue,
    };

    if (type === "gollacFilterName") {
      updatedParams.gollacFilterName = event.target.value;
    }
    if (type === "gollacManagerAttributeName") {
      updatedParams.gollacManagerAttributeName = event.target.value;
    }
    if (type === "ceilsGollacMinValue") {
      updatedParams.ceilsGollacMinValue = validateAndAdjustValue(
        event.target.value!,
        gollacParams.ceilsGollacMaxValue!,
        true
      );
    }
    if (type === "ceilsGollacMaxValue") {
      updatedParams.ceilsGollacMaxValue = validateAndAdjustValue(
        event.target.value!,
        gollacParams.ceilsGollacMinValue!,
        false
      );
    }

    setGollacParams(updatedParams);
  };

  async function editReferenceGollacParams() {
    store.dispatch(filterStatus(STATUS_SAVING));

    const response: any = await store.dispatch(
      accountUpdateOptions({
        ...session.accountOptions,
        gollacFilterName: gollacParams.gollacFilterName!,
        gollacManagerAttributeName: gollacParams.gollacManagerAttributeName!,
        ceilsGollacMinValue: gollacParams.ceilsGollacMinValue!,
        ceilsGollacMaxValue: gollacParams.ceilsGollacMaxValue!,
      })
    );

    if (response.error) {
      store.dispatch(filterStatus(STATUS_SAVE_ERROR));
    } else {
      for (const key in gollacParams) {
        store.dispatch(sessionEditAccountOptions([key], gollacParams[key]));
      }

      store.dispatch(filterStatus(STATUS_SAVED));
    }
  }

  return (
    <Card>
      <Typography sx={{ pl: 4, my: 2 }}>
        <b>{t("golac_params_title")} </b>
      </Typography>

      <Stack
        sx={{
          p: 3,
          alignItems: "end",
          flex: 1,
        }}
        direction="row"
        spacing={1}
      >
        <Stack direction="column" spacing={1}>
          <Stack
            sx={{
              flex: 1,
              p: 3,
            }}
            direction="row"
            spacing={1}
          >
            <FormControl sx={{ m: 1, minWidth: 140 }}>
              <InputLabel id="gollac-filter-select-label">
                {t("filter_reference_manager_gollac")}
              </InputLabel>
              <Select
                labelId="gollac-filter-name-label"
                id="gollac-filter-name-select-id"
                value={gollacParams.gollacFilterName ?? ""}
                label={t("filter_reference_manager_gollac")}
                onChange={(e) =>
                  handleChangeGollacParams(e, "gollacFilterName")
                }
              >
                {filter.list.map((filter: Filter) => (
                  <MenuItem key={filter.name} value={filter.name}>
                    {filter.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {gollacParams.gollacFilterName ? (
              <FormControl sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="gollac-manager-attribute-name-select-label">
                  {t("filter_attribute_reference_manager_gollac")}
                </InputLabel>
                <Select
                  labelId="gollac-manager-attribute-name-select-label"
                  id="gollac-manager-attribute-name-select-id"
                  value={gollacParams.gollacManagerAttributeName ?? ""}
                  label={t("filter_attribute_reference_manager_gollac")}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeGollacParams(e, "gollacManagerAttributeName")
                  }
                >
                  {filter.list
                    .find(
                      (f: Filter) => f.name === gollacParams.gollacFilterName
                    )
                    ?.populations.map((pop: FilterPopulation) => (
                      <MenuItem key={pop.name} value={pop.name}>
                        {pop.name}
                      </MenuItem>
                    )) || []}
                </Select>
              </FormControl>
            ) : null}

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <TextField
                type="number"
                id="gollac-ceil-min-value"
                label={t("golac_step_neglect")}
                variant="outlined"
                value={gollacParams.ceilsGollacMinValue}
                slotProps={{
                  htmlInput: {
                    step: 1,
                    min: 0,
                    max: gollacParams.ceilsGollacMaxValue! - 1,
                  },
                }}
                sx={{ width: 120 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const adjustedValue = validateAndAdjustValue(
                    e.target.value,
                    gollacParams.ceilsGollacMaxValue!,
                    true
                  );
                  handleChangeGollacParams(
                    {
                      ...e,
                      target: {
                        ...e.target,
                        value: adjustedValue.toString(),
                      },
                    },
                    "ceilsGollacMinValue"
                  );
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  handleBlurGollacParams(e, "ceilsGollacMinValue")
                }
              />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <TextField
                type="number"
                id="gollac-ceil-max-value"
                label={t("golac_step_high")}
                variant="outlined"
                value={gollacParams.ceilsGollacMaxValue}
                slotProps={{
                  htmlInput: {
                    step: 1,
                    min: gollacParams.ceilsGollacMinValue! + 1,
                    max: 100,
                  },
                }}
                sx={{ width: 120 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const adjustedValue = validateAndAdjustValue(
                    e.target.value,
                    gollacParams.ceilsGollacMinValue!,
                    false
                  );
                  handleChangeGollacParams(
                    {
                      ...e,
                      target: {
                        ...e.target,
                        value: adjustedValue.toString(),
                      },
                    },
                    "ceilsGollacMaxValue"
                  );
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  handleBlurGollacParams(e, "ceilsGollacMaxValue")
                }
              />
            </FormControl>
          </Stack>

          <Stack
            sx={{
              ml: 5,
              flex: 1,
            }}
            direction="row"
            spacing={1}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Chip
                sx={{
                  width: 150,
                  fontWeight: 700,
                }}
                color="success"
                label={`${t("golac_step_neglect")} < ${
                  gollacParams.ceilsGollacMinValue
                }`}
              />
              <WbSunnyIcon color="success" sx={{ ml: 1 }} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Chip
                sx={{
                  width: 150,
                  fontWeight: 700,
                }}
                color="warning"
                label={`${gollacParams.ceilsGollacMinValue} < ${t(
                  "golac_step_moderate"
                )} < ${gollacParams.ceilsGollacMaxValue}`}
              />
              <WbCloudyIcon color="warning" sx={{ ml: 1 }} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Chip
                sx={{
                  width: 150,
                  backgroundColor: "idtTheming.idtRed",
                  fontWeight: 700,
                }}
                label={`${gollacParams.ceilsGollacMaxValue} > ${t(
                  "golac_step_high"
                )}`}
                color="error"
              />
              <ThunderstormIcon color="error" sx={{ ml: 1 }} />
            </Box>
          </Stack>
        </Stack>

        <Button
          onClick={handleSetGollacParams}
          sx={{
            justifyContent: "end",
            height: 45,
          }}
          variant="contained"
          color="success"
        >
          <b>{t("utils_save")}</b>
        </Button>
      </Stack>
    </Card>
  );
};
