/** 
 * ACTION-PLAN
 * List of task to do for the company or a manager
 */

import { Topic } from "./topic.types"
import { User } from "./user.types" 
import { t } from "@/translate/t"

export type ActionPlanStatus = "draft" | "active" | "closed" | "archived"

export class ActionPlanTaskMessage{
  id : string = ""
  text : string = ""
  user : string = ""
  userId : string = ""
  date : Date = new Date()
}

export type ActionPlanTaskStatus = "todo" | "pending" | "done"

export class ActionPlanTask{
  createdAt : Date = new Date()
  createdBy : string = ""
  id : string = ""
  text : string = ""
  messages : ActionPlanTaskMessage[] = []
  topicAid : string | null = null
  status : ActionPlanTaskStatus = "todo"

  constructor(item: Partial<ActionPlanTask> = {}){
    Object.assign(this, item)
  }

  get isDone():boolean{
    return this.status === "done"
  }

  getTopic(topics:Topic[]):Topic{
    const topic = topics.find(x => x.aid === this.topicAid)
    return topic ? topic : new Topic()
  }

}

export class ActionPlan{
  allowAddTask : boolean = true //Allow the manager to add his own tasks
  AccountId : string = "" //Account related
  dateEnd : Date | null = null //Date end for the plan to be finished
  dateStart : Date | null = null //Estimated date start
  dateSend : Date | null = null //When the mail is send
  dateOpen : Date | null = null //When the user responsible open the action plan
  expectedOutcome : string = "" //Expected outcome
  id : string = "" //Primary key
  status : ActionPlanStatus = "draft" //Status of the plan
  priority : string = "" //Focus for the action plan
  tasks : ActionPlanTask[] = [] //List of tasks related to the plan
  referenceSurveyId : string | null = null //Reference of the survey
  UserIdResponsible : string | null = null //Id of the user accountable
  UserIdContact : string | null = null //Id of the responsible
  UserContact : User | null = null //User to contact

  constructor(item: Partial<ActionPlan> = {}){
    if (!item) item = new ActionPlan()
    if (item.UserContact) item.UserContact = new User(item.UserContact)
    item.tasks = item.tasks ? item.tasks.map(x => new ActionPlanTask(x)) : []
    Object.assign(this, item)
  }

  get statusLabel():string{
    return t("tasks_status_" + this.status)
  }

  get tasksDoneCount():number{
    return this.tasks.filter(x => x.isDone).length
  }

}

//List all status in order to have the 3 column of the kanban
export const TASK_STATUS_LIST:ActionPlanTaskStatus[] = ["todo", "pending", "done"]

export interface ActionPlanState{
  active : ActionPlan //Selected action plan
  list : ActionPlan[] //List of action plan loaded
  referenceSurveyId : string | null //Save reference surveyId for next actions plans
  status : string //Statut (loading, saving)
}

export const ACTION_PLAN_ACTIVATE : string = "ACTION_PLAN_ACTIVATE"
export const ACTION_PLAN_ADD : string = "ACTION_PLAN_ADD"
export const ACTION_PLAN_ADD_TASK : string = "ACTION_PLAN_ADD_TASK"
export const ACTION_PLAN_ADD_TASK_MESSAGE : string = "ACTION_PLAN_ADD_TASK_MESSAGE"
export const ACTION_PLAN_EDIT : string = "ACTION_PLAN_EDIT"
export const ACTION_PLAN_EDIT_REFERENCE_SURVEY_ID:string = "ACTION_PLAN_EDIT_REFERENCE_SURVEY_ID"
export const ACTION_PLAN_EDIT_TASK : string = "ACTION_PLAN_EDIT_TASK"
export const ACTION_PLAN_GET : string = "ACTION_PLAN_GET"
export const ACTION_PLAN_INIT = 'ACTION_PLAN_INIT'
export const ACTION_PLAN_REMOVE : string = "ACTION_PLAN_REMOVE"
export const ACTION_PLAN_REMOVE_TASK : string = "ACTION_PLAN_REMOVE_TASK"
export const ACTION_PLAN_REMOVE_TASK_MESSAGE : string = "ACTION_PLAN_REMOVE_TASK_MESSAGE"
export const ACTION_PLAN_STATUS : string = "ACTION_PLAN_STATUS"