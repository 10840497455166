/* environnement config */
/* for a react app var should be prefixed by "REACT_APP_" */

class Env{
  REACT_APP_URL_GA: string = (typeof REACT_APP_URL_GA !== 'undefined') ? REACT_APP_URL_GA : "http://localhost:2345"
  //REACT_APP_URL_GA: string = (typeof REACT_APP_URL_GA !== 'undefined') ? REACT_APP_URL_GA : "https://dev-ga-mummk.ondigitalocean.app"
  //REACT_APP_URL_GA: string = (typeof REACT_APP_URL_GA !== 'undefined') ? REACT_APP_URL_GA : "https://ga.id-tree.com"
  REACT_APP_URL_BACKEND: string = (typeof REACT_APP_URL_BACKEND !== 'undefined') ? REACT_APP_URL_BACKEND : "http://localhost:5004"
  REACT_APP_URL_NUMIND: string = (typeof REACT_APP_URL_NUMIND !== 'undefined') ? REACT_APP_URL_NUMIND : "https://numind.id-tree.com"
  REACT_APP_URL_QUICKVOTE: string = (typeof REACT_APP_URL_QUICKVOTE !== 'undefined') ? REACT_APP_URL_QUICKVOTE : "http://localhost:3001"
  REACT_APP_URL_LEARNING: string = (typeof REACT_APP_URL_LEARNING !== 'undefined') ? REACT_APP_URL_LEARNING : "http://localhost:3003"
  REACT_APP_URL_QRCODE: string = (typeof REACT_APP_URL_QRCODE !== 'undefined') ? REACT_APP_URL_QRCODE : "http://localhost:5005"
  REACT_APP_URL_OBSERVER: string = (typeof REACT_APP_URL_OBSERVER !== 'undefined') ? REACT_APP_URL_OBSERVER : "https://dev-observer.id-tree.com"
  REACT_APP_URL_REPORTING: string = (typeof REACT_APP_URL_REPORTING !== 'undefined') ? REACT_APP_URL_REPORTING : "http://localhost:5002"
  REACT_APP_URL_SPACE: string = (typeof REACT_APP_URL_SPACE !== 'undefined') ? REACT_APP_URL_SPACE : "https://idtree-dev.fra1.digitaloceanspaces.com"
  REACT_APP_TRACK_LOGIN: boolean = (typeof REACT_APP_TRACK_LOGIN !== 'undefined') ? REACT_APP_TRACK_LOGIN : true
  REACT_APP_SOCKET_TOKEN: string = (typeof REACT_APP_SOCKET_TOKEN !== 'undefined') ? REACT_APP_SOCKET_TOKEN : "1f87d9d3-57e8-44a8-8f5e-3d78357ec80c"
  REACT_APP_BUILD_HASH: string = (typeof REACT_APP_BUILD_HASH !== 'undefined') ? REACT_APP_BUILD_HASH : "0123456"
  REACT_APP_BUILD_DATE: number = (typeof REACT_APP_BUILD_DATE !== 'undefined') ? REACT_APP_BUILD_DATE : 1700000000000
  REACT_APP_FIREBASE: any = {
    apiKey: "AIzaSyA5ir2xEAq_MPx1j1Hic0MUIuomdwhJy4w",
    authDomain: "idtree-8bf28.firebaseapp.com",
    databaseURL: "https://idtree-8bf28.firebaseio.com",
    projectId: "idtree-8bf28",
    storageBucket: "idtree-8bf28.appspot.com",
    messagingSenderId: "460815433220"
  }
}

//Read environnement variable to override
const env: Env = new Env()

export default env