/** 
 * TASKS-TOPIC-LABEL.WIDGET
 * Display name of the topic for one task
 */
import { connect } from "react-redux"
import { ActionPlanTask } from "@/redux/action-plan.types"
import { TopicState } from "@/redux/topic.types"

interface StateProps{
  topic : TopicState
}

interface OwnProps{
  currentTask : ActionPlanTask
}

type Props = StateProps & OwnProps

function TasksTopicLabelWidget(props:Props) {
  
  return (
    <div style={{ 
        fontSize : 12,
        color : props.currentTask.getTopic(props.topic.list).Axis?.color 
      }}>

      <b>
        {props.currentTask.getTopic(props.topic.list).label}
      </b>

      { props.currentTask.getTopic(props.topic.list).Axis &&
      <div>
        {props.currentTask.getTopic(props.topic.list).Axis?.label}
      </div>
      }

      <div style={{ height : 6 }}/>

    </div>
  )
}

const mapStateToProps = state => ({
  topic : state.topic
})

export default connect(mapStateToProps)(TasksTopicLabelWidget)