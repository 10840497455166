/**
 * DASHBOARD-RPS
 * Burnout indicators
 */
import { Component } from "react";
import NavDashboard from "@/nav/nav.dashboard";
import { Session } from "@/redux/_session.types";
import { connect } from "react-redux";
import UpgradeWidget from "@/widgets/upgrade.widget";
import DashboardRpsTeamsWidget from "@/widgets/dashboard-rps-teams.widget";
import Tabs from "@/components/tabs";
import { withTranslation, WithTranslation } from "react-i18next";
import DashboardRpsQuestionsWidget from "@/widgets/dashboard-rps-questions.widget";
import DashboardRpsOverviewWidget from "@/widgets/dashboard-rps-overview.widget";
import DashboardRpsUsersWidget from "@/widgets/dashboard-rps-users.widget";
import { SurveyState } from "@/redux/survey.types";
import { FilterState } from "@/redux/filter.types";
import { DashboardRpsGollacWidget } from "@/widgets/dashboard-rps-gollac.widget";

interface Props extends WithTranslation {
  _session: Session
  filter: FilterState
  survey : SurveyState
}

interface State {
  currentTab: string;
}

//List of tabs
const TAB_QUESTIONS = "TAB_QUESTIONS"
const TAB_TEAMS = "TAB_TEAMS"
const TAB_USERS = "TAB_USERS"
const TAB_GOLLAC = "TAB_GOLLAC";

class DashboardRps extends Component<Props> {
  public readonly state: Readonly<State> = {
    currentTab: TAB_TEAMS,
  };

  render() {
    const { t } = this.props;

    return (
      <NavDashboard title="rps_title" 
        hideFilters={!this.props._session.modules.rps}
        hideOptions={!this.props._session.modules.rps}
        helpPage={23}>
        {!this.props._session.modules.rps ? (
          <UpgradeWidget feature="rps" />
        ) : (
          <div>

            <DashboardRpsOverviewWidget dashboardFilters={this.props.filter.dashboard}/>

            {( this.props.survey.active.rps.total > 0 && this.props._session.accountLevel !== "free" ) &&
            <div>

              <Tabs
                active={this.state.currentTab}
                onClick={(currentTab) => this.setState({ currentTab })}
                tabs={{
                  TAB_TEAMS: t("rps_teams_title"),
                  TAB_QUESTIONS: t("rps_questions_title"),
                  TAB_USERS : t("rps_users_title"),
                  TAB_GOLLAC: t("rps_gollac_title")
                }}
              />

              { this.state.currentTab === TAB_TEAMS && 
              <DashboardRpsTeamsWidget/>
              }

              { this.state.currentTab === TAB_QUESTIONS &&
              <DashboardRpsQuestionsWidget dashboardFilters={this.props.filter.dashboard} />
              }

              { this.state.currentTab === TAB_USERS &&
              <DashboardRpsUsersWidget dashboardFilters={this.props.filter.dashboard} />
              }

              {this.state.currentTab === TAB_GOLLAC && (
              <DashboardRpsGollacWidget/>
              )}

            </div>
            }

          </div>
        )}
      </NavDashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  _session : state._session,
  filter: state.filter,
  survey : state.survey
});

export default connect(mapStateToProps)(withTranslation()(DashboardRps));
