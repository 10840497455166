/** 
 * HELP-MODAL
 * Tutorial displayed on the right side of the screen
 */
import Card from "@/components/card"
import { withTranslation, WithTranslation } from "react-i18next"
import Button from "./button"
import Scroller from "./scroller"
import Space from "./space"
import { SlideIn } from "@/utils/animations.utils"

interface OwnProps extends WithTranslation{
  children? : any
  isLarge? : boolean
  onClose? : Function
  title : string
  isLeft?: boolean
}

function HelpModal(props:OwnProps) {
  const { t } = props

  return (
    <div className={"modal-help-container flex " + (props.isLarge ? "modal-help-large" : "")}>
      <div className={`modal-help modal-help-${props.isLeft ? "left" : "right"}`}>
        <SlideIn isLeft={props.isLeft}>
          <Card title={props.title}>
            
            <div className={`modal-help-content modal-help-content-${props.isLeft ? "left" : "right"}`}>
              <Scroller maxHeight={400}>
                <div style={props.isLeft ? {paddingLeft: "20px"} : {paddingRight: "20px"}}>
                  {props.children}
                </div>
              </Scroller>
            </div>

            { props.onClose &&
            <div className="flex" style={{ marginTop : '32px' }}>
              <Space/>
              <Button onClick={props.onClose} className="primary">
                {t("utils_close")}
              </Button>
            </div>
            }

          </Card>
        </SlideIn>
      </div>
    </div>

  )
}

export default withTranslation()(HelpModal)