/** 
 * LINK
 * Button of type link (underlined)
 */
import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface OwnProps{
  children : any //Content inside
  icon? : IconDefinition
  isWithoutMargin? : boolean //No margin
  isWhite? : boolean //Display text in white
  onClick? : Function //Action onClick
}

function Link(props:OwnProps) {

  //Action on clic
  function click(){
    if (props.onClick){
      props.onClick()
    }
  }

  //Style
  //White text if defined
  function getStyle(){
    let color
    let margin

    if (props.isWhite){
      color = 'white'
    }

    if (props.isWithoutMargin){
      margin = '0px'
    }
    
    return {
      color,
      margin
    }

  }

  return (
    <div className="link grey-t"
      onClick={click}
      style={getStyle()}>

      {props.icon &&
      <FontAwesomeIcon icon={props.icon}/>
      }
      
      {props.children}
    </div>
  )

}

export default Link