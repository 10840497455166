import { useEffect, useState } from "react"
import ListIcon from "./list-icon"
import { WithTranslation, withTranslation } from "react-i18next"
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from 'react-tooltip'
import { BounceIn } from "@/utils/animations.utils"

interface OwnProps{
  animationDuration?: number
  onClick?: Function
}

type Props = OwnProps & WithTranslation

function AiAssistantButton(props: Props){
  const { t } = props

  const [isAnimated, setIsAnimated] = useState<boolean>(props.animationDuration ? true : false)

  useEffect(() => {
    const duration: number = props.animationDuration ? props.animationDuration : 0

    const animationInterval: NodeJS.Timeout = setInterval(() => setIsAnimated(false), duration)

    return () => { clearInterval(animationInterval) }
    
  }, [
    props.animationDuration
  ])

  function click(){
    if(props.onClick){
      props.onClick()
    }
  }

  return (
    <div
      className={"_hover" + (isAnimated ? " _animated" : " grey-t")}
      style={{
        border: `1px solid ${isAnimated ? "transparent" : "#8c8c8c"}`,
        borderRadius: 20,
        marginTop: -3,
      }}
      data-tip={t("module_assistant")}
      onClick={click}
    >
      <div
        className="flex"
        style={{
          backgroundColor: "#ffffff",
          borderRadius: 20,
          padding: "2px 6px",
        }}
      >
                
        <BounceIn>
          <ListIcon
            onClick={click}
            isAnimated={isAnimated}
            fallbackIcon={faGraduationCap}
          />
        </BounceIn>

        {isAnimated ?
        <div
          className="_animated"
          style={{
            marginLeft: -23,
            backgroundClip: "text",
            color: "transparent"
          }}
        >
          {t("module_assistant")}
        </div>
        :
        <div style={{marginLeft: -23}} />
        }

      </div>

      <ReactTooltip/>

    </div>
  )



}

export default withTranslation()(AiAssistantButton)