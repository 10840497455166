/** 
 * DASHBOARD-QUESTIONS.WIDGET
 * Display list of results for a question
 */

import { connect } from "react-redux"
import Card from "@/components/card";
import { useEffect } from "react";
import { FilterState } from "@/redux/filter.types";
import { store } from "@/index";
import { loadParticipation, loadQuestionsAndTopics } from "@/redux/_archive.actions";
import { TopicState } from "@/redux/topic.types";
import { Axis, AxisState } from "@/redux/axis.types";
import { topicOpen, topicStatus } from "@/redux/topic.actions";
import { SurveyState } from "@/redux/survey.types";
import PageLoader from "@/components/page-loader";
import { INSUFFICIENT_PARTICIPATION, STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import NoResultsWidget from "./no-results.widget";
import { Session } from "@/redux/_session.types";
import LoadingModal from "@/modals/loading.modal";
import DashboardAxisWidget from "./dashboard-axis.widget";
import { Question, QuestionState } from "@/redux/question.types";
import { questionActivate } from "@/redux/question.actions";
import QuestionTipsWidget from "./question-tips.widget";
import HelpModal from "@/components/modal-help"

interface StateProps{
  _session : Session
  axis : AxisState
  filter : FilterState
  question: QuestionState
  survey : SurveyState
  topic : TopicState
}

function DashboardQuestionsWidget(props:StateProps) {

  //Load questions on every change on the dashboard filters
  useEffect(() => {
    store.dispatch(loadQuestionsAndTopics(props.filter.dashboard))
    store.dispatch(loadParticipation(props.filter.dashboard))
  }, [
    props.filter.dashboard,
    props.survey.active.randomData
  ])

  //Detect when page is load if scroll down to specific topic is required
  useEffect(() => {
    if (props.topic.status === "PAGE_LOADED"){

      const queryParams = new URLSearchParams(window.location.search);
      const topicId:string = queryParams.get('topic_id')!
      const axisId:string = queryParams.get("axis_id")!

      if (topicId){
        const topic = props.topic.list.find(x => x.id === topicId)
        if (topic){
          if (!topic.open) store.dispatch(topicOpen(topic))
          document.querySelector("#details-topic-" + topicId)?.scrollIntoView({
            behavior: 'smooth'
          })
        }
      }

      else if (axisId){
        document.querySelector("#details-axis-" + axisId)?.scrollIntoView({
          behavior: 'smooth'
        })
      }

      //Reset URL params and mark page as loaded
      window.history.pushState({}, '', window.location.pathname)
      store.dispatch(topicStatus(STATUS_LOADED))

    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.topic.status])

  //reset active question on load
  useEffect(() => {
    store.dispatch(questionActivate(new Question()))
  }, [])


  return (
    props.topic.status === INSUFFICIENT_PARTICIPATION
    ?
    <NoResultsWidget/>
    :
    <PageLoader status={props.topic.status}>

      { props.axis.status === STATUS_LOADING &&
      <LoadingModal/> 
      }

      {/* props._session.modules.learning &&
      <DashboardLearningsHelp/>
      */}

      { /*Owl assistant giving advices thanks to gpt*/
      props.question.active.id.length > 0 &&
      <HelpModal
        title={`Conseils sur la question « ${props.question.active.label} »`}
        onClose={() => store.dispatch(questionActivate(new Question()))}
        isLeft>
        <QuestionTipsWidget
          question={props.question.active}
        />
      </HelpModal>
      }
        
      { props.axis.list.concat([new Axis()]).map((axis, i) =>
      (
      props._session.accountId === "6654c17d-5678-4277-a4ff-04d43b255fa5" && 
      props._session.userRole === "OBSERVER" && 
      axis.id === ""
      ?
      null
      :
      <Card isWithoutPadding 
        key={i}>

        <DashboardAxisWidget
          axis={axis}
          isAxisOpenable
        />

      </Card>
      ))}

    </PageLoader>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  axis : state.axis,
  filter : state.filter,
  question : state.question,
  survey : state.survey,
  topic : state.topic
})

export default connect(mapStateToProps)(DashboardQuestionsWidget)