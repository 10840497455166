/** 
 * EDITBAR-BUTTON
 * Button for the edit bar
 *  */
import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Space from "./space"
import ReactTooltip from 'react-tooltip'

interface OwnProps{
  icon : IconDefinition //Icon to display (if no image)
  isActive? : boolean //Selected (take active color)
  isSmall? : boolean //Small button
  onClick : Function //Function trigger on click
  text : string //Text to display for the button
}

function EditbarButton(props:OwnProps) {
  return (
    <div className={"editbar-button flex flex-dcol _hover" + (props.isActive ? " editbar-button-active" : "") + (props.isSmall ? " editbar-button-sm" : "")}
      onClick={() => props.onClick()}>

      <Space/>

      <FontAwesomeIcon icon={props.icon} 
        className="flex-auto"
        data-tip={props.text}/>
      <ReactTooltip/>

      { !props.isSmall &&
      props.text
      }

      <Space/>

    </div>
  )

}

export default EditbarButton