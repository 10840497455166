import { faCircleNotch, faShieldAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Card from "@/components/card"
import Checkbox from "@/components/checkbox"
import Dropdown from "@/components/dropdown"
import ListIcon from "@/components/list-icon"
import Space from "@/components/space"
import env from "@/env"
import { store } from "@/index"
import { orderBy } from "lodash"
import { useEffect, useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { Session } from "@/redux/_session.types"
import { accountActivate } from "@/redux/account.actions"
import { Account } from "@/redux/account.types"
import { subscriptionFetchAll_AsSupervisor } from "@/redux/subscription.actions"
import { Subscription } from "@/redux/subscription.types"

interface StateProps{
  _session: Session
}

interface OwnProps{
  onClick: Function
}

type Props = StateProps & OwnProps & WithTranslation

const REQUIRED_LEVEL: number = 2 //supervisor level needed to see all accounts

function SubscriptionsAllWidget(props: Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const [accounts, setAccounts] = useState<Account[]>([])
  const [startDay, setStartDay] = useState<number>(0)
  const [all, setAll] = useState<boolean>(props._session.supervisorLevel < REQUIRED_LEVEL ? false : true)
  const [nbToDisplay, setNbToDisplay] = useState<number>(-1)


  useEffect(() => {
    async function load(){

      const response: any = await store.dispatch(subscriptionFetchAll_AsSupervisor(all, startDay))

      setNbToDisplay(20)

      if(!response.error){
        setAccounts( 
          response.map((x: any) => {
            return new Account({ ...x, options: {image: x.image === "true"}, Subscriptions: orderBy(x.Subscriptions, ["dateEnd"], ["asc"]) })
          })
        )
      }

    }

    load()

  }, [
    all,
    startDay
  ])

  function edit(account:Account){
    store.dispatch(accountActivate(account))
    navigate("/supervisor/account?accountId=" + account.id + "#subscriptions_section")
  }


  return (
    <div>

      <div className="flex">

        <div className="flex flex-dcol">
          <Space/>
          <p>{ t("subscriptions_show") + " " + ( startDay < 0 ? t("expired_since") : t("expiring_in") )+ "   " }</p>
          <Space/>
        </div>

        <Dropdown value={startDay+""}
          displayField={"name"}
          active={"id"}
          list={[
            {id: "-366", name: "-366"},
            {id: "-180", name: "-180"},
            {id: "-90", name: "-90"},
            {id: "-60", name: "-60"},
            {id: "-30", name: "-30"},
            {id: "-15", name: "-15"},
            {id: "-7", name: "-7"},
            {id: "0", name: "0"},
            {id: "7", name: "+7"},
            {id: "15", name: "+15"},
            {id: "30", name: "+30"},
            {id: "60", name: "+60"},
            {id: "90", name: "+90"},
            {id: "180", name: "+180"},
            {id: "366", name: "+366"},
          ]}
          onSelect={(e: any)=>setStartDay(parseInt(e.id, 10))}
        />

        <div className="flex flex-dcol">
          <Space/>
          <p>{"   " + t("utils_time_days", { value : "", s: "s" })}</p>
          <Space/>
        </div>

      </div>

      { props._session.supervisorLevel >= REQUIRED_LEVEL &&
      <Checkbox active={!all} onClick={() => setAll(!all)} text={t("supervisor_accounts_display")}/>
      }

      {accounts.slice(0, nbToDisplay).map((account: Account) =>
      <Card key={account.id} className="_hover" onClick={() => edit(account)}>
        <div className="flex1 flex">
          <div className="flex flex-dcol">
            <Space/>
            <ListIcon fallbackIcon={faShieldAlt}
              iconColor={"#20CA7E"}
              image={account.options.image ? env.REACT_APP_URL_SPACE.concat("/Accounts/", account.id, ".png?" + Date.now()) : null}
            />
            <Space/>
          </div>
          <div className="flex1">{account.name}</div>

          <div>
            {t("account_app_level_" + account.level)}
          </div>

        </div>

        <div className="height-20"/>

        <div>
          {(account.Subscriptions.map((subscription: Subscription) =>
          <div key={subscription.id}>
            
            <div className="flex">
              <div className={"width-40" + (subscription.remainingDays! < 0 ? " red-t" : "")}>{subscription.remainingDays ? subscription.remainingDays + "j" : null}</div>
              <div>{subscription.dateEnd ? new Date(subscription.dateEnd).toLocaleDateString() : " - "}</div> 
              <div className="width-20"/>
              <div>{subscription.text}</div>

              <Space/>

              <div className="width-20"/>
              <div className="flex width-60">
                <Space/>
                {subscription.price + "€"}
              </div>
            </div>

            {subscription.note2 &&
            <p className="red-t">
              <b>
                {subscription.note2}
              </b>
            </p>
            }

          </div>
          ))}
        </div>
      </Card>
      )}

      <div className="flex">
        <Space/>
        { nbToDisplay < accounts.length ?
        <FontAwesomeIcon icon={faCircleNotch} spin />
        :
        <div/>
        }
        <Space/>
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(SubscriptionsAllWidget))