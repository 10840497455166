/**
 * FILTER-DESCRIPTION-DISPLAYER.WIDGET
 * 
 */

import Space from "@/components/space";
import { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next"
import { DashboardFilters } from "@/redux/filter.types";
import { FilterDescription, generateDescription } from "@/utils/generate-context-description.utils";

interface OwnProps{
  dashboardFilters: DashboardFilters
  referenceDate?: Date
  nbAttributes?: number
  defaultPlaceholder?: string
}

type Props = OwnProps & WithTranslation

const DEFAULT_NB_ATTRIBUTES_TO_DISPLAY: number = 3 //How many attributes to display per filter ?

function FilterDescriptionDisplayerWidget(props: Props){
  const { t } = props

  const [description, setDescription] = useState<FilterDescription[]>(generateDescription(props.dashboardFilters, props.referenceDate ? props.referenceDate : new Date()))
  const [nbAttributes, setNbAttributes] = useState<number>(props.nbAttributes ? props.nbAttributes : DEFAULT_NB_ATTRIBUTES_TO_DISPLAY)

  useEffect(() => {
    setDescription(generateDescription(props.dashboardFilters, props.referenceDate ? props.referenceDate : new Date()))
  }, [
    props.dashboardFilters,
    props.referenceDate
  ])

  useEffect(() => {
    setNbAttributes(props.nbAttributes ? props.nbAttributes : DEFAULT_NB_ATTRIBUTES_TO_DISPLAY)
  }, [
    props.nbAttributes
  ])

  return(
    description.length > 1 ?
    <ul style={{fontSize: "12px"}}>
      {description.map((filterDescription: FilterDescription)=>
      <li key={filterDescription.id} style={{marginRight: 20}}>
        <span style={{fontWeight: 600}}>{filterDescription.id}</span>
        {" : "}
        <span>
          {filterDescription.values.length > nbAttributes ?
          <span>
            {filterDescription.values.slice(0, nbAttributes).join(", ")}
            <span style={{fontWeight: 600}}>{" + " + t("utils_more", {count: (filterDescription.values.length - nbAttributes), s: (filterDescription.values.length - nbAttributes) > 1 ? "s" : ""})}</span>
          </span>
          : 
          <span>{filterDescription.values.join(", ")}</span>
          }
        </span>
        
      </li>
      )}
    </ul>
    :
    <div className="flex flex-dcol" style={{ height: "100%", fontSize: 16, padding: "0px 10px", textAlign: "center"}}>
      <Space/>
      {description.length === 1 ?
      <>
        {description[0].id}

        <div style={{fontWeight: 600}}>
          {description[0].values.length > nbAttributes ?
            description[0].values.slice(0, nbAttributes).join(", ")
          :
            description[0].values.join(", ")
          }
        </div>
      </>
      :
      <div style={{fontWeight: 600}}>{props.defaultPlaceholder ? props.defaultPlaceholder : t("no_filter_selected")}</div>
      }
      <Space/>
    </div>
  )
}




export default withTranslation()(FilterDescriptionDisplayerWidget)