//INDEX.JS
//Root file of the app

//external modules
import { Root, createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistStore,  persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import "@/translate/i18n";
import "@/style/components.css";
import "@/style/index.css";
import "@/style/routes.css";
import "@/style/widgets.css";
import "animate.css";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import App from "./App";
import * as serviceWorker from "@/serviceWorker";
import reportWebVitals from "@/reportWebVitals";
import apiMiddleware from "@/utils/api-middleware.utils";

//Internal modules
import rootReducer from "@/redux/_index.reducers";
import { supervisorInit } from "@/redux/supervisor.actions";
import { accountInit } from "@/redux/account.actions";
import { attributeInit } from "@/redux/attribute.actions";
import { userInit } from "@/redux/user.actions";
import { projectInit } from "@/redux/project.actions";
import { surveyInit } from "@/redux/survey.actions";
import { subscriptionInit } from "@/redux/subscription.actions";
import { packInit } from "@/redux/pack.actions";
import { templateInit } from "@/redux/template.actions";
import { topicInit } from "@/redux/topic.actions";
import { filterInit } from "@/redux/filter.actions";
import { questionInit } from "@/redux/question.actions";
import { sessionInit } from "@/redux/_session.actions";
import { propositionInit } from "@/redux/proposition.actions";
import { axisInit } from "@/redux/axis.actions";
import { bookmarkInit } from "@/redux/bookmark.actions";
import { learningInit } from "@/redux/learning.actions";
import { lessonInit } from "@/redux/lesson.actions";
import { regroupingInit } from "@/redux/regrouping.actions";
import { invitationInit } from "@/redux/invitation.actions";
import { sendingInit } from "@/redux/sending.actions";
import { reminderInit } from "@/redux/reminder.actions";
import { actionPlanInit } from "@/redux/action-plan.actions";
import { reportInit } from "@/redux/report.actions";
import { pageInit } from "@/redux/page.actions";

// mui theming
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./customization/theming";
import { configureStore } from "@reduxjs/toolkit";

//Configuration store redux
// Config persistant
const persistConfig = {
  key: "root",
  storage,
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(apiMiddleware)
})


//Save localstorage
const persistor = persistStore(store);

//Init redux store
//Rehydrate objects on start
const initReducers = () => {
  store.dispatch(sessionInit());
  store.dispatch(accountInit());
  store.dispatch(actionPlanInit());
  store.dispatch(axisInit());
  store.dispatch(attributeInit());
  store.dispatch(bookmarkInit());
  store.dispatch(filterInit());
  store.dispatch(learningInit());
  store.dispatch(lessonInit());
  store.dispatch(invitationInit());
  store.dispatch(packInit());
  store.dispatch(pageInit());
  store.dispatch(propositionInit());
  store.dispatch(questionInit());
  store.dispatch(regroupingInit());
  store.dispatch(reminderInit());
  store.dispatch(reportInit());
  store.dispatch(sendingInit());
  store.dispatch(subscriptionInit());
  store.dispatch(supervisorInit());
  store.dispatch(surveyInit());
  store.dispatch(projectInit());
  store.dispatch(templateInit());
  store.dispatch(topicInit());
  store.dispatch(userInit());
};

//Load fond
/*
WebFont.load({
  google: { families: ['Open Sans:400,600,700', 'sans-serif'] }
})P
*/

const root: Root = createRoot(document.getElementById("root") as HTMLElement);

//Init DOM
root.render(
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
      onBeforeLift={initReducers}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();