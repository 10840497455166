import Space from "@/components/space";
import { Box, Button, Card, Divider, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { useSelector } from "react-redux";
import DashboardHeatmapWidget from "./dashboard-heatmap.widget";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import WbCloudyIcon from "@mui/icons-material/WbCloudy";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { selectSession } from "@/core/slices/session/session-selector";

export const DashboardRpsGollacWidget = () => {
  const session = useSelector(selectSession);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
  }));

  return (
    <Card>
      {session.accountOptions.gollacFilterName && session.accountOptions.gollacManagerAttributeName ? (
        <Box>
          <Box sx={{ p: 3 }}>
            <Typography component="p">{t("golac_heatmap_info")}</Typography>
            <br />
            <Typography component="p" sx={{ mb: 1 }}>
              <i>{t("golac_heatmap_info_legend")}</i>
            </Typography>

            <Stack
              direction="column"
              sx={{ alignItems: "center" }}
              spacing={1}
              divider={<Divider orientation="horizontal" flexItem />}
            >
              <Stack
                direction="row"
                sx={{ alignItems: "center" }}
                spacing={1}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Item>
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    component="p"
                  >
                    {t("filter_reference_manager_gollac")} :{" "}
                    {session.accountOptions.gollacFilterName}
                  </Typography>
                </Item>

                <Item>
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    component="p"
                  >
                    {t("filter_attribute_reference_manager_gollac")} :{" "}
                    {session.accountOptions.gollacManagerAttributeName}
                  </Typography>
                </Item>
              </Stack>

              <Stack
                direction="row"
                sx={{ alignItems: "center" }}
                spacing={1}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Item>
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    component="p"
                  >
                    {`${t("golac_step_neglect")} < ${
                      session.accountOptions.ceilsGollacMinValue
                    }`}{" "}
                    : <WbSunnyIcon color="success" sx={{ ml: 1 }} />
                  </Typography>
                </Item>

                <Item>
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    component="p"
                  >
                    {`${session.accountOptions.ceilsGollacMinValue} < ${t(
                      "golac_step_moderate"
                    )} < ${session.accountOptions.ceilsGollacMaxValue}`}{" "}
                    : <WbCloudyIcon color="warning" sx={{ ml: 1 }} />
                  </Typography>
                </Item>

                <Item>
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    component="p"
                  >
                    {`${session.accountOptions.ceilsGollacMaxValue} > ${t(
                      "golac_step_high"
                    )}`}{" "}
                    : <ThunderstormIcon color="error" sx={{ ml: 1 }} />
                  </Typography>
                </Item>
              </Stack>
            </Stack>
          </Box>

          <DashboardHeatmapWidget isGollac={true} />
        </Box>
      ) : (
        <Box sx={{ m: 2, display: "flex", alignItems: "center" }}>
          <b>
            {t("filter_reference_manager_gollac")} : {t("utils_undefined")}{" "}
          </b>
          <Space />
          {session.userRole === "ADMIN" ? (
            <Button variant="outlined" href="/adm/filters">
              {t("adm_filter_route")}
            </Button>
          ) : (
            <Box>{t("contact_admin")}</Box>
          )}
        </Box>
      )}
    </Card>
  );
};
