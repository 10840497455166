/** 
 * PROJECT-EDIT-SENDING.MODAL
 * Add new sending for the project
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import Modal from "@/components/modal";
import { ProjectState } from "@/redux/project.types";
import { useEffect, useState } from "react";
import TextInput from "@/components/text-input";
import { v4 as uuid } from "uuid";
import { formatDate, updateDate } from "@/utils/format-date.utils";
import { store } from "@/index";
import { projectStatus } from "@/redux/project.actions";
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types";
import ModalConfirm from "@/components/modal-confirm";
import { Sending, SendingState } from "@/redux/sending.types";
import { sendingAdd, sendingDestroy, sendingRemove, sendingReplace, sendingUpdate } from "@/redux/sending.actions";
import { EmailState } from "@/redux/email.types";
import Dropdown from "@/components/dropdown";
import { emailActivate } from "@/redux/email.actions";
import Link from "@/components/link";
import ProjectEmailContentWidget from "@/widgets/project-email-content.widget";
import i18n from "@/translate/i18n";
import findProgramDate from "@/utils/find-program-date.utils";
import { toast } from "react-toastify";
import TimePicker from 'react-time-picker';
 
interface StateProps extends WithTranslation{
  email : EmailState
  project : ProjectState
  sending : SendingState
}

interface OwnProps{
  date? : Date
  editedSending? : Sending
  isNow? : boolean
  onClose : Function
}

type Props = StateProps & OwnProps

const MODAL_ASK_DELETE = "MODAL_ASK_DELETE"
const MODAL_ASK_SEND_NOW = "MODAL_ASK_SEND_NOW"
const MODAL_ASK_WITH_RELAUNCHES = "MODAL_ASK_WITH_RELAUNCHES"
const MODAL_PREVIEW_EMAIL = "MODAL_PREVIEW_EMAIL"

function ProjectEditSendingModal(props:Props) {
  const { t } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [date, setDate] = useState<Date>(props.editedSending ? new Date(props.editedSending.date) : initDate(props.date ? props.date : new Date()))

  //Activate mail according the index of the email in the array
  useEffect(() => {
    let email

    if (props.isNow){
      email = props.email.list[0]
    }else if (!props.editedSending){
      email = props.email.list[props.sending.list.length] 
    }else{
      email = props.email.list.find(x => x.id === props.editedSending?.EmailId)
    }

    if (email){
      store.dispatch(emailActivate(email))
    }

  }, [
    props.isNow,
    props.editedSending,
    props.email.list,
    props.sending.list.length
  ])
  
  //Init date : set hour at 9:30
  function initDate(date:Date){
    date.setHours(9)
    date.setMinutes(30)
    return date
  }

  //Next
  function next(){
    if (props.isNow){
      setCurrentModal(MODAL_ASK_SEND_NOW)
    }else if (props.sending.list.length === 0){
      setCurrentModal(MODAL_ASK_WITH_RELAUNCHES)
    }else{
      nextConfirm(false)
    } 
  }

  //Proceed on next
  async function nextConfirm(isWithRelaunches:boolean){

    if (props.email.list.length === 0){
      toast(t("email_empty"), { type : "error" })
      setCurrentModal(null)
    }else{

      const sending = new Sending({
        id : props.editedSending ? props.editedSending.id : uuid(),
        date : props.isNow ? new Date() : updateDate(date, date, false),
        isDone : props.isNow ? true : false,
        EmailId : props.email.active.id
      })

      //Save data
      store.dispatch(projectStatus(STATUS_SAVING))
      await store.dispatch(sendingUpdate(sending, props.project.active.id, props.isNow ? true : false))

      //Add or edit reminder
      if (props.editedSending){
        store.dispatch(sendingReplace(sending))
      }else{
        store.dispatch(sendingAdd(sending))
      }

      //Add relanches
      if (isWithRelaunches){

        for (let i = 1; i < props.email.list.length; i++) {
          const email = props.email.list[i]
          const relaunch = new Sending({
            id : uuid(),
            date : findProgramDate(sending.date, i + 1),
            EmailId : email.id
          })

          await store.dispatch(sendingUpdate(relaunch, props.project.active.id, false))
          store.dispatch(sendingAdd(relaunch))
          
        }
        
      }

      //End
      store.dispatch(projectStatus(STATUS_SAVED))

      //Close modal
      props.onClose()

    }

  }

  //Delete the reminder
  function deleteReminder(){
    if (props.editedSending){
      store.dispatch(projectStatus(STATUS_SAVING))
      store.dispatch(sendingDestroy(props.editedSending.id))
      store.dispatch(sendingRemove(props.editedSending.id))
      store.dispatch(projectStatus(STATUS_SAVED))
      props.onClose()
    }
  }

  return (
    <Modal title={props.isNow ? t("sending_title_now") : t("sending_title")}
      isCloseButtonVisible
      disableClickOutside
      status={props.project.status}
      onClose={() => props.onClose()}
      onDelete={props.editedSending ? () => setCurrentModal(MODAL_ASK_DELETE) : undefined}
      onNext={() => next()}>

      { currentModal === MODAL_ASK_DELETE &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        onYes={() => deleteReminder()}
        text={t("sending_ask_remove")}
        textBold={t("utils_next_ask")}
      />
      }

      { currentModal === MODAL_ASK_SEND_NOW &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        onYes={() => nextConfirm(false)}
        text={t("sending_ask_send_now")}
        textBold={t("utils_next_ask")}
      />
      }

      { currentModal === MODAL_ASK_WITH_RELAUNCHES &&
      <ModalConfirm onNo={() => nextConfirm(false)}
        onYes={() => nextConfirm(true)}
        textBold={t("sending_ask_relaunches")}
      />
      }

      { currentModal === MODAL_PREVIEW_EMAIL &&
      <Modal onClose={() => setCurrentModal(null)}
        isCloseButtonVisible>
        <ProjectEmailContentWidget
          currentLanguage={i18n.language}
          skin={props.project.active.emailSkin}  
        />
      </Modal>
      }

      <div className="flex">

        <Dropdown value={props.email.active.name}
          displayField="name"
          active={props.email.active.id}
          list={props.email.list}
          title={t("sending_email")}
          onSelect={(e) => store.dispatch(emailActivate(e))}
          error={props.email.list.length === 0 ? true : false}
        />

        <div style={{ marginTop : 42 }}>
          <Link onClick={() => setCurrentModal(MODAL_PREVIEW_EMAIL)}>
            {t("utils_preview")}
          </Link>
        </div>

      </div>

      { !props.isNow &&
      <div className="flex">

        <TextInput type="date"
          isFullWidth
          onChange={(e) => setDate(updateDate(date, new Date(e.value), false))}
          title={t("sending_date")}
          value={formatDate(date, false, true)}
        />

        <div className="width-20"/>

        <div>

          <div className="text-input-title grey-t">
            {t("sending_hour")}
          </div>

          <TimePicker 
            onChange={(time) => {
              if (time){
                setDate(new Date(new Date(date).toDateString() + " " + time))
              }
            }}
            value={date.toTimeString().split(' ')[0]}
            disableClock={true}
          />

        </div>

        <div className="width-20"/>

      </div>
      }

      <div className="height-20"/>

      <div className="flex">

        <div className="flex1">

          <p className="grey-t">
            {t("sending_help_1")}
          </p>

          <p className="grey-t">
            {t("sending_help_2")}
          </p>

        </div>

        <div className="width-40"/>

        <img src={require("@/assets/check.png")} alt="check"
          width={200} height={200}
        />

      </div>

      
      <div className="height-100"/>
      
    </Modal>
  )

}

const mapStateToProps = state => ({
  email : state.email,
  project : state.project,
  sending : state.sending
})

export default connect(mapStateToProps)(withTranslation()(ProjectEditSendingModal))