/**
 * SENDING.TYPES
 * 
 */

export class Sending {
  id : string = ""
  date : Date = new Date()
  EmailId : string = ""
  isDone : boolean = false
  ProjectId : string = ""

  constructor(item: Partial<Sending> = {}){
    if (!item) item = new Sending()
    item.date = item.date ? new Date(item.date) : new Date()
    Object.assign(this, item)
  }

  get dateLabel():string{
    return new Date(this.date).toLocaleDateString(navigator.language, {
      hour : '2-digit',
      minute :'2-digit'
    })
  }

  getIsFirst(sendings:Sending[]):boolean{
    return sendings.findIndex(x => x.id === this.id) === 0
  }

}

export interface SendingState{
  list : Sending[]
}

export const SENDING_ADD : string = "SENDING_ADD"
export const SENDING_GET : string = "SENDING_GET"
export const SENDING_INIT : string = "SENDING_INIT"
export const SENDING_REPLACE : string = "SENDING_REPLACE"
export const SENDING_REMOVE : string = "SENDING_REMOVE"