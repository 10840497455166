/** 
 * LIST-ICON
 * Little round image in list
 */
import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Space from "./space"
import { useEffect, useState } from "react"

interface OwnProps{
  image? : string | null
  fallbackIcon : IconDefinition
  iconColor? : string,
  onClick? : Function
  spin?: boolean
  isAnimated?: boolean
}

const animationColor1: string = "#ff4800"
const animationColor2: string = "#da54d8"
const animationColor3: string = "#0092f4"

function ListIcon(props:OwnProps) {

  const [isAnimated, setIsAnimated] = useState<boolean>(props.isAnimated === true)

  useEffect(() => {
    setIsAnimated(props.isAnimated === true)
  }, [props.isAnimated])

  function click(e: any){
    e.stopPropagation()
    if (props.onClick){
      props.onClick()
    }
  }

  function getClassName(){
    let className = "list-icon flex grey-t"

    if (props.onClick){
      className += " list-icon-editable"
    }

    return className
  }

  return (
    <div className={getClassName()}
      onClick={click}>

      <svg width="0" height="0">
        <linearGradient id="my-gradient" gradientTransform="rotate(150)">
          <stop offset="13.4%" stopColor={animationColor1}>
            <animate attributeName="stop-color" values={`${animationColor1}; ${animationColor2}; ${animationColor3}; ${animationColor1}`} dur="2s" repeatCount="indefinite"/>
          </stop>
          <stop offset="50%" stopColor={animationColor2}>
            <animate attributeName="stop-color" values={`${animationColor2}; ${animationColor3}; ${animationColor1}; ${animationColor2}`} dur="2s" repeatCount="indefinite"/>
          </stop>
          <stop offset="86.6%" stopColor={animationColor3}>
            <animate attributeName="stop-color" values={`${animationColor3}; ${animationColor1}; ${animationColor2}; ${animationColor3}`} dur="2s" repeatCount="indefinite"/>
          </stop>
        </linearGradient>
      </svg>

      { props.image
      ?
      <img src={props.image}
        alt="list-icon"
        style={{ backgroundColor : props.iconColor }}
      />
      :
      <div className="list-icon-fallback flex">
        <Space/>

        <FontAwesomeIcon icon={props.fallbackIcon}
          className={isAnimated ? "list-icon-animation" : undefined}
          style={{ 
            color: props.iconColor,
          }}
          spin={props.spin}
        />
        <Space/>
      </div>
      }

      <Space/>

    </div>
  )
}

export default ListIcon