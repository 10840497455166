/** 
 * TASKS-LIST.WIDGET
 * Get list of actions to do for a user
 */
import Button from "@/components/button"
import Space from "@/components/space"
import { store } from "@/index"
import { useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { ActionPlanState, ActionPlanTask, TASK_STATUS_LIST } from "@/redux/action-plan.types"
import { topicActivate } from "@/redux/topic.actions"
import { Topic, TopicState } from "@/redux/topic.types"
import Link from "@/components/link"
import TasksCardWidget from "./tasks-card.widget"
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import TasksDescriptionWidget from "./tasks-description.widget"
import { actionPlanEditTask } from "@/redux/action-plan.actions"
import { Session } from "@/redux/_session.types"
import TasksEditModal from "@/modals/tasks-edit.modal"

interface StateProps extends WithTranslation{
  _session : Session
  actionPlan : ActionPlanState
  topic : TopicState
}

//Add new task modal
const MODAL_ADD = "MODAL_ADD"

function TasksListWidget(props:StateProps) {
  const { t } = props

  //Display modal
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Add new task without topic pre defined
  function addTask(){
    store.dispatch(topicActivate(new Topic()))
    setCurrentModal(MODAL_ADD)
  }

  //Change status of a tasks by dragging drop
  function changeStatus(e){
    let task = props.actionPlan.active.tasks.find(x => x.id === e.draggableId)
    if (e.destination && task){
      task = new ActionPlanTask(task)
      task.status = e.destination.droppableId
      store.dispatch(actionPlanEditTask(task))
    }
  }

  return (
    <div className="flex1">

      { props.actionPlan.active.status === "active" &&
      <TasksDescriptionWidget/>
      }

      { currentModal === MODAL_ADD &&
      <TasksEditModal onClose={() => setCurrentModal(null)}/>
      }

      { (props.actionPlan.active.tasks.length === 0 && props.actionPlan.active.status === "draft") &&
      <div className="upload-button medgrey-b grey-t flex flex-dcol"
        style={{
          maxHeight : 340,
          padding : 40,
          textAlign : "center" 
        }}>

        <Space/>

        <div className="flex">
          <Space/>
          <img alt="no-task" width="120"
            src={require("@/assets/sherlock.png")}>
          </img>
          <Space/>
        </div>

        <div className="height-20"/>

        <div>
          <b>
            {t("tasks_list_empty")}
          </b>
        </div>

        <div className="height-20"/>

        <div style={{ fontSize : 12 }}>
          {t("tasks_list_empty_help")}
        </div>

        <div className="height-20"/>

        <div className="flex">
          <Space/>
          <Button isWithBorder
            onClick={() => addTask()}>
            {t("tasks_list_add")}
          </Button>
          <Space/>
        </div>

        <Space/>

      </div>
      }

      { props.actionPlan.active.status !== "active"
      ?
      <div>
        
        { props.actionPlan.active.tasks.map((task: ActionPlanTask, i: number) =>
        <TasksCardWidget
          key={task.id}
          draggableIndex={i}
          currentTask={task}
        />
        )
        }

        { 
        (
          props.actionPlan.active.tasks.length > 0 &&
          props.actionPlan.active.status === "draft"
        ) 
        &&
        <div className="flex">
          <Space/>
          <Link isWithoutMargin
            onClick={addTask}>
            {t("tasks_list_add")}
          </Link>
        </div>
        }

      </div>
      :
      <DragDropContext onDragEnd={changeStatus}>
        <div className="flex tasks-column-container">

          { TASK_STATUS_LIST.map(status => 
          <div key={status} className="flex1 tasks-column medgrey-b">
            <Droppable droppableId={status} type="TASKS_STATUS">
              { provided => (
              <div ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ height : "100%" }}>
                  
                <div className="tasks-column-title medgrey white-t">
                  {t("tasks_status_" + status)}
                </div>

                <div className="tasks-column-card-container">

                  { props.actionPlan.active.tasks.filter(x => x.status === status).map((task: ActionPlanTask, i: number) =>
                  <TasksCardWidget draggableIndex={i}
                    key={task.id} 
                    isDraggable 
                    currentTask={task}
                  />
                  )
                  }

                  { status === "todo" &&
                  <div style={{
                      padding : 16,
                      textAlign : "center",
                      marginBottom : 16
                    }}>
                    
                    {
                    (
                      props._session.userRole === "ADMIN" || 
                      props.actionPlan.active.allowAddTask
                    ) 
                    &&
                    <Link isWithoutMargin
                      onClick={addTask}>
                      {t("tasks_list_add")}
                    </Link>
                    }

                  </div>
                  }

                  {provided.placeholder}

                </div>

              </div>
              )
              }
            </Droppable>
          </div>
          )
          }
        
        </div>
      </DragDropContext>
      }

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  actionPlan : state.actionPlan,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(TasksListWidget))