/** 
 * SUBSCRIPTION
 * Specific prestation for an account
 */

import { msToTime } from "@/utils/format-date.utils"

export class Subscription{
  id : string = ""
  date : Date = new Date()
  dateStart : Date = new Date()
  dateEnd : Date | null = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)
  price : number = 0
  text : string = ""
  paid : boolean = true
  bill : boolean = false
  note1 : string = ""
  note2 : string = ""
  AccountId: string = ""

  constructor(subscription: Partial<Subscription> = {}){
    //subscription.Account = new Account(subscription.Account)
    Object.assign(this, subscription)
  }

  get dateStartInput():string{
    return new Date(this.dateStart).toISOString().split('T')[0]
  }

  get dateEndInput():string | null{
    return this.dateEnd ? new Date(this.dateEnd).toISOString().split('T')[0] : null
  }

  get dateStartLabel():string{
    return new Date(this.dateStart).toLocaleDateString()
  }

  get duration(): number | null{
    return this.dateEnd ? msToTime(new Date(this.dateEnd).getTime() - new Date(this.dateStart).getTime()).days : null
  }

  get remainingDays(): number | null{
    return this.dateEnd ? msToTime(new Date(this.dateEnd).getTime() - Date.now()).days : null
  }
  
}

export interface SubscriptionState{
  list : Subscription[]
}

export const SUBSCRIPTION_ADD = 'SUBSCRIPTION_ADD'
export const SUBSCRIPTION_EDIT = 'SUBSCRIPTION_EDIT'
export const SUBSCRIPTION_GET = 'SUBSCRIPTION_GET'
export const SUBSCRIPTION_INIT = 'SUBSCRIPTION_INIT'
export const SUBSCRIPTION_REMOVE = 'SUBSCRIPTION_REMOVE'