/** 
 * LIST-BUTTON
 * Little button with icon
 */
import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { connect } from "react-redux"
import ReactTooltip from 'react-tooltip'
import { useState } from "react"
import ActionsMenu from "./actions-menu"
import { Session } from "@/redux/_session.types"
import { v4 as uuid } from 'uuid';

interface StateProps {
  _session : Session
}

interface ActionState{
  icon : IconDefinition
  id : string
  isInactive? : boolean
  label : string,
}

interface OwnProps{
  actions? : ActionState[] //If defined, open a context menu with actions
  dot? : boolean //Show dot
  dotColor? : string //color of the dot
  text? : string //Text for tooltip
  icon : IconDefinition //Icon
  isColorActive? : boolean //Color is active and not cta
  isFat? : boolean //Fat button primary
  isInactive? : boolean //Is the button inactive
  isPrimary? : boolean //Is the button a call to action
  onAction? : Function //Clic on item of action menu
  onClick? : Function //Action on click
}

type Props = StateProps & OwnProps

function ListButton(props:Props) {

  //Id of the button (for tooltip reference)
  const [buttonId] = useState(uuid())

  //Open context menu of actions
  const [openActions, setOpenActions] = useState(false)

  //Action on clic
  function click(e){
    e.stopPropagation()

    if (props.actions){
      setOpenActions(true)
    }

    if (props.onClick){
      props.onClick()
    }

  }

  //Clic on action
  function clickAction(actionId){
    setOpenActions(false)
    if (props.onAction){
      props.onAction(actionId)
    }
  }

  function getClassName(){
    let className = "list-button grey-t"

    if (props.isInactive){
      className += " list-button-inactive"
    }

    return className

  }

  //Style
  //Color CTA if primary
  function getStyle(){
    const defaultColor = props.isColorActive ? props._session.accountColors.active : props._session.accountColors.cta
    let backgroundColor
    let color

    if (props.isFat){
      backgroundColor = defaultColor
      color = "white"
    }else if (props.isPrimary){
      color = defaultColor
    }

    return {
      backgroundColor,
      color
    }

  }

  return (
    <div className="rel">

      { props.dot &&
      <div className={"abs list-button-dot "+(props.dotColor?props.dotColor:"red")} />
      }

      <button className={getClassName()}
        data-tip={props.text}
        data-for={buttonId}
        onClick={click}
        style={getStyle()}>
        <FontAwesomeIcon icon={props.icon}/>
      </button>

      { props.text &&
      <ReactTooltip 
        globalEventOff="click"
        id={buttonId}
      />
      }

      <div className="rel dark-t">
        { (openActions && props.actions) &&
        <ActionsMenu actions={props.actions}
          positionRight
          onClose={() => setOpenActions(false)}
          onSelect={clickAction}
        />
        }
      </div>

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(ListButton)