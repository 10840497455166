/** 
 * REPORT-PAGE-CONTENT.WIDGET.TSX
 * Edit content for a page
 */
import ImagePicker from "@/components/image-picker";
import ListDropdown from "@/components/list-dropdown";
import { store } from "@/index";
import { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { pageEdit, pageFormatEditorState } from "@/redux/page.actions";
import { PAGE_CONTENT_SCREENSHOT_CONFIGS, Page, PageContent, PageContentScreenshot, PageContentScreenshotParam, PageContentScreenshotType, PageSide, PageState } from "@/redux/page.types";
import { v4 as uuid } from 'uuid';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import { Session } from "@/redux/_session.types";
import { useClickOutside } from 'react-click-outside-hook'
import env from "@/env";
import { reportEdit, reportGetTagsFromTexts, reportUpdateTagsData } from "@/redux/report.actions";
import { ReportState } from "@/redux/report.types";
import { keys, merge, snakeCase } from "lodash";
import { SurveyState } from "@/redux/survey.types";
import ReportScreenshotGlobalResultsWidget from "./report-screenshot-global-results.widget";
import ReportScreenshotAxesWidget from "./report-screenshot-axes.widget";
import ReportScreenshotPodiumWidget from "./report-screenshot-podium.widget";
import ReportScreenshotQuestionsWidget from "./report-screenshot-questions.widget";
import ListItem from "@/components/list-item";
import { Topic, TopicState } from "@/redux/topic.types";
import Checkbox from "@/components/checkbox";
import Space from "@/components/space";
import Link from "@/components/link";
import ReportScreenshotHeatmapWidget from "./report-screenshot-heatmap.widget";
import { ACCOUNT_RESULTS_TYPES } from "@/redux/account.types";
import { FilterState } from "@/redux/filter.types";
import { faChartPie, faComment, faFont, faImage, faList, faSortNumericDown, faStar, faTh, faThList } from "@fortawesome/free-solid-svg-icons";
import EditbarButton from "@/components/editbar-button";
import { FadeIn } from "@/utils/animations.utils";
import { STATUS_SAVING } from "@/redux/_status.types";
import { topicGroupForReport } from "@/redux/topic.actions";
import ReportScreenshotMessagesWidget from "./report-screenshot-messages.widget";
import { AxisState } from "@/redux/axis.types";

interface StateProps extends WithTranslation{
  _session : Session
  axis : AxisState
  filter : FilterState
  page : PageState
  report : ReportState
  survey : SurveyState
  topic : TopicState
}

interface OwnProps{
  pageContent : PageContent
  currentPage : Page
  isRight? : boolean
  isSmall? : boolean
}

type Props = StateProps & OwnProps

function ReportPageContentWidget(props:Props) {
  const { t } = props

  //Init editor State
  //> editorState is the raw text for edition (with the variable as "survey_name")
  //> editorStateFormatted include the translation for the variables
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [editorStateFormatted, setEditorStateFormatted] = useState(EditorState.createEmpty())
  const [pageContent, setPageContent] = useState(props.pageContent)
  
  //Init is edited
  const [isEdited, setIsEdited] = useState(false)

  //The user have click to edit
  const [isClicked, setIsClicked] = useState(false)

  //Detect click outiside the component => will set isEdited as false
  const [ref, hasClickedOutside] = useClickOutside()

  //Click oustide
  useEffect(() => {
    setIsEdited(false)
  }, [hasClickedOutside])

  //Detect when isEdited is switching from true to false
  //> edit the store
  useEffect(() => {

    async function editEnd(){
      if (!isEdited && isClicked){

        //Edit text blocks
        const rawBlocks = convertToRaw(editorState.getCurrentContent()).blocks
        setEditorStateFormatted(pageFormatEditorState(rawBlocks, true))
        edit("textBlocks", rawBlocks)
  
        //Update tags data
        const contentTags = reportGetTagsFromTexts(rawBlocks.map(x => x.text))
        const contentTagsData = await reportUpdateTagsData(contentTags)
        if (keys(contentTagsData).length > 0){
          store.dispatch(reportEdit("tagsData", merge(props.report.active.tagsData, contentTagsData)))
        }
        
      }
    }

    editEnd()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdited, isClicked])

  //Detect stasus changed betweed isEdited (for disable navigation with key)
  useEffect(() => {
    store.dispatch(reportEdit("keyDownAllowed", !isEdited))
  }, [isEdited])

  //Update state when page is changed or tags data is updated
  useEffect(() => {
    setPageContent(props.pageContent)
    setEditorStateFormatted(pageFormatEditorState(props.pageContent.textBlocks, true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.page.active.id
  ])

  //Init image ID for upload image
  const [imageId] = useState(uuid())

  //Add or remove topic for the list of topic to hide
  function displayTopic(topic:Topic){
    if (topic.aid){
      const hideItems = !pageContent.screenshot.isTopicHidden(topic) ? pageContent.screenshot.hideItems.concat([topic.aid]) : pageContent.screenshot.hideItems.filter(x => x !== topic.aid)
      editScreenshot("hideItems", hideItems)
    }
  }

  //Change the content (nested param)
  function edit(key:string, value:any){

    const contentKey:PageSide = props.isRight ? "contentRight" : "contentLeft"
    const newPageContent = new PageContent({
      ...props.page.active[contentKey],
      [key] : value,
    })

    editPage(contentKey, newPageContent)
    setPageContent(newPageContent)

  }

  //Edit page
  function editPage(key:string, value:any){
    store.dispatch(pageEdit(key, value))
  }

  //Edit screenshot value
  function editScreenshot(key:string, value:any){
    
    const newScreenshot = new PageContentScreenshot({
      ...pageContent.screenshot,
      [key] : value,
    })

    edit("screenshot", newScreenshot)

  }

  //Return screenshot
  function getScreenshot(){
    return (
      <div id={props.isSmall ? undefined : ("image-exportable-report-screenshot-" + (props.isRight ? "right" : "left") + "-" + props.currentPage.id)}
        style={{ maxHeight : 334 }}>
        { props._session.interfaceType === "SUPERVISOR"
        ?
        t("page_type_screenshot_" + pageContent.screenshot.type)
        :
        getScreenshotWidget()
        }
      </div>
    )
  }

  //Return associated screenshot
  //Do not reload data for each card if only preview
  function getScreenshotWidget(){
    switch(pageContent.screenshot.type){
      case "axes":
        return <ReportScreenshotAxesWidget currentSurvey={props.survey.active}
          isFullWidth={!props.currentPage.twoColumns}
          currentPage={props.currentPage}
        />
      case "heatmap":
        return <ReportScreenshotHeatmapWidget resultsType={pageContent.screenshot.resultsType} 
          filterName={pageContent.screenshot.filterName}
          //pagePopulations={pagePopulations}
          />
      case "global_results":
        return <ReportScreenshotGlobalResultsWidget currentSurvey={props.survey.active}
          customTitle={pageContent.screenshot.customTitle}
          currentPage={props.currentPage}
        />
      case "podium":
        return <ReportScreenshotPodiumWidget currentSurvey={props.survey.active} 
          isFullWidth={!props.currentPage.twoColumns}
          displayQuestions={pageContent.screenshot.displayQuestions}
        />
      case "questions":
        return <ReportScreenshotQuestionsWidget 
          currentPage={props.currentPage}
          displayFullScale={pageContent.screenshot.displayFullScale}
          currentTopics={props.page.status === STATUS_SAVING ?
            props.currentPage.Topics :
            topicGroupForReport(props.currentPage).length > 0 ? topicGroupForReport(props.currentPage)[0] : []
          }
        />
      case "messages":
        return <ReportScreenshotMessagesWidget isFullWidth={!props.currentPage.twoColumns}
          summarize={pageContent.screenshot.summarize}
          currentPage={props.currentPage}
          currentTopic={props.currentPage.Topics.length > 0 ? props.currentPage.Topics[0] : new Topic()}
        />
      default:
        return 
    }
  }

  //List of options for each params
  function getScreenshotParamOptions(param:PageContentScreenshotParam){
    switch(param){
      case "filterName":
        return props.filter.list
      case "resultsType":
        return ACCOUNT_RESULTS_TYPES.map(x => { return { id : x, name : t(x) } })
      default:
        return [
          { id : true, name : t("utils_yes") },
          { id : false, name : t("utils_no") }
        ]
    }
  }

  //Get value for the parameters for screenshot
  function getScreenshotParamValue(param:PageContentScreenshotParam){
    const options:any = getScreenshotParamOptions(param)
    const option:any = options.find(x => x.id === pageContent.screenshot[param])
    return option ? option.name : ""
  }

  //Get all type for the screenshot
  //If page is right do not allow fullscreen widgets
  function getScreenshotTypes(){
    return PAGE_CONTENT_SCREENSHOT_CONFIGS.map(x => { return { id : x.type, name : t("page_type_screenshot_" + x.type), params : x.params }})
  }

  //Get matching icon
  function getScreenshotIcon(screenshotType:PageContentScreenshotType){
    switch(screenshotType){
      case "global_results":
        return faChartPie
      case "axes":
        return faThList
      case "heatmap":
        return faTh
      case "podium":
        return faSortNumericDown
      case "questions":
        return faList
      case "messages":
        return faComment
      default:
        return faStar
    }
  }

  //Quit edit mode on escape
  function keyDownEdited(e){
    if (e.key === "Escape"){
      setIsEdited(false)
    }
  }

  //Convert text to raw 
  function onEditorStateChange(e:EditorState){
    setEditorState(e)
  }

  //Open edit mode
  //Do not apply if small preview (nav bar top)
  function openEditMode(){
    if (!props.isSmall){
      setIsEdited(true)
      setIsClicked(true)
      setEditorState(pageFormatEditorState(props.pageContent.textBlocks, false))
    }
  }

  return (
    <div className="flex1 flex flex-auto"
      ref={ref}
      style={{
        padding : "2%",
        maxHeight : 300,
        overflow : (pageContent.type === "text" && !props.isSmall) ? "auto" : "hidden",
        marginTop : (pageContent.type === "screenshot" && isEdited) ? 32 : undefined
      }}>

      {isEdited
      ?
      <div className="report-page-content"
        onKeyDown={keyDownEdited}>

        <FadeIn className="abs report-page-content-type report-page-editor-toolbar flex-wrap flex">

          <EditbarButton onClick={() => edit("type", "text")}
            isActive={pageContent.type === "text"}
            icon={faFont}
            text={t("page_type_text")}
          />

          <Space/>

          <div className="flex flex-wrap"
            style={{ width : 160 }}>

            { getScreenshotTypes().map(screenshotType =>
            <div key={screenshotType.id}
              className="flex"
              style={{ height : 0 }}>

              <EditbarButton isSmall
                isActive={pageContent.type === "screenshot" && pageContent.screenshot.type === screenshotType.id}
                onClick={() => {
                  edit("type", "screenshot")
                  editScreenshot("type", screenshotType.id)
                }}
                icon={getScreenshotIcon(screenshotType.id)}
                text={screenshotType.name}
              />

              { (
                pageContent.type === "screenshot" && 
                pageContent.screenshot.type === screenshotType.id && 
                screenshotType.params.length > 0
              ) &&
              <div className="abs" 
                style={{ 
                  width : 200,
                  left : 92,
                  top : 8
                }}>
                
                { screenshotType.params.map(param =>
                <div key={param}>

                  <div style={{ fontSize : 10, padding : "0 4px" }}>
                    {t("page_type_screenshot_param_" + snakeCase(param))}
                  </div>
                
                  <ListDropdown active={pageContent.screenshot[param]}
                    positionRight
                    onSelect={(e) => editScreenshot(param, e.id)}
                    value={getScreenshotParamValue(param)}
                    values={getScreenshotParamOptions(param)}
                  />

                </div>
                )
                }
              </div>
              }

            </div>
            )
            }

            <div style={{ height : 0 }} className="flex">

              <EditbarButton onClick={() => edit("type", "image")}
                isSmall
                isActive={pageContent.type === "image"}
                icon={faImage}
                text={t("page_type_image")}
              />
            </div>

          </div>

        </FadeIn>

        { pageContent.type === "text" &&
        <Editor
          toolbar={{
            options: ['colorPicker', 'inline', 'fontSize'],
            inline : { options : ["bold"] },
            fontSize : { options: [10, 14, 18, 24, 32, 36, 42] },
            colorPicker : {
              colors: ["#111C2B", "#8C8C8C", "#20CA7E", "#ff9500", "#eb5a46"],
            }
          }}
          editorState={editorState}
          wrapperClassName="report-page-editor"
          editorClassName="report-page-editor-textarea"
          onEditorStateChange={(e) => onEditorStateChange(e)}
        />

        }

        { pageContent.type === "image" &&
        <ImagePicker
          id={imageId}
          square
          imageId={props.pageContent.imageId ? props.pageContent.imageId : null}
          model="ReportPages"
          onDelete={() => edit("imageId", null)}
          onSave={() => edit("imageId", imageId)}
        />
        }

        { pageContent.type === "screenshot" &&
        getScreenshot()
        }
        
        { (
          pageContent.type === "screenshot" &&
          (pageContent.screenshot.type === "questions" || pageContent.screenshot.type === "messages") && 
          !pageContent.screenshot.displayAll
        ) &&
        <div style={{
            height : 342,
            overflowY : "auto",
            borderRadius: "0px 0px 6px 0px",
            marginTop: 41,
            position: "absolute",
            top: 49,
            right: 0,
            backgroundColor: "#2d2d2d",
            color: "white",
            zIndex : 1
          }}>

          <ListItem isSmall>
            <Link isWithoutMargin 
              onClick={() => editScreenshot("hideItems", pageContent.screenshot.hideItems.length === props.topic.list.length ? [] : props.topic.list.map(x => x.aid))}>
              {t(pageContent.screenshot.hideItems.length === props.topic.list.length ? "page_question_activate_all" : "page_questions_unable_all")}
            </Link>
          </ListItem>

          { props.axis.list.map(axis => 
          <div key={axis.id}>

            <ListItem isSmall>
              <b>{axis.label}</b>
            </ListItem>

            { props.topic.list.filter(x => x.AxisId === axis.id).map(topic =>
            <div key={topic.id}>
              <ListItem isSmall>
                <Checkbox active={!pageContent.screenshot.isTopicHidden(topic)} 
                  onClick={() => displayTopic(topic)}
                  text={topic.label}
                />
                <Space/>
              </ListItem>
            </div>
            )
            }

          </div>
          )
          }

          { props.topic.list.filter(x => !x.AxisId).map(topic =>
          <div key={topic.id}>
            <ListItem isSmall>
              <Checkbox active={!pageContent.screenshot.isTopicHidden(topic)} 
                onClick={() => displayTopic(topic)}
                text={topic.label}
              />
              <Space/>
            </ListItem>
          </div>
          )
          }

        </div>
        }

      </div>
      :
      <div className="report-page-content _hover"
        onClick={openEditMode}>

        { pageContent.type === "text" &&
        <Editor toolbarHidden
          placeholder={t("page_placeholder_description")}
          editorState={editorStateFormatted}
          readOnly
        />
        }

        { pageContent.type === "image" &&
        <img src={env.REACT_APP_URL_SPACE + "/ReportPages/" + pageContent.imageId + ".png"}
          id={"report-image-" + pageContent.imageId}
          alt={"image" + pageContent.imageId}
          style={{ width : "75%" }}/>
        }

        { pageContent.type === "screenshot" &&
        (
          props._session.interfaceType === "SUPERVISOR"
          ?
          t("page_type_screenshot_" + pageContent.screenshot.type)
          :
          getScreenshot()
        )
        }

      </div>
      }

    </div>
  )
  
}

const mapStateToProps = state => ({
  _session : state._session,
  axis : state.axis,
  filter : state.filter,
  page : state.page,
  report : state.report,
  survey : state.survey,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(ReportPageContentWidget))